@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
.FB header.FGSRES {
    display: none;
}

.fb-fgsres-table {
    display: none;
}

.fb-fgsres-tables-m {
    display: block;
}

.fbOddsTable header.football-fgsres-title-m>.fbTitle {
    display: flex;
    border-radius: 0px;
    margin-bottom: 15px;
}

.fb-fgsres-table-m {
    background-color: white;
    border-radius: 4px;
    margin-bottom: 15px;
    /* font-weight: bold; */
}

.fb-fgsres-table-header-m {
    display: flex;
    flex-direction: column;
    min-height: 70px;
}

.fb-fgsres-table-header-m .h2a {
    font-size: 15px;
    padding-top: 10px;
    flex: 1;
}

.fb-fgsres-table-header-m > div, .fb-fgsres-table-body-m > div{
    display: flex;
}

.fb-fgsres-table-header-m .h-name, .fb-fgsres-table-header-m .a-name {
    flex: 1;
    display: flex;
    align-items: center;
    color:#405A55;
}

.fb-fgsres-table-header-m .h-name{
    justify-content: flex-end;
    text-align: right;
}

.fb-fgsres-table-header-m .vs-container{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fb-fgsres-table-header-m .vs{
    width: 24px;
    height: 24px;
    background-color: #FFD505;
    border-radius: 2px;
    font-size: 11px;
    text-align: center;
    padding-top: 2px
}

.fb-fgsres-table-header-m .a-name{
    justify-content: left;
}

.fb-fgsres-table-header-m .event-id{
    flex: 1;
    text-align: right;
}

.fb-fgsres-table-header-m .match-date{
    width: 82px;
    text-align: center;
}

.fb-fgsres-table-header-m .tourn-name{
    flex: 1;
    text-align: left;
}

.fb-fgsres-table-header-m .other-information {
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 11px;
    /* font-weight: 400; */
}

.fb-fgsres-table-body-m div{
    border-top: 1px solid #e4e4e4;
    min-height: 32px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.fb-fgsres-table-body-m .title{
    display: flex;
    align-items: center;
    width: 33%;
    padding-left: 8px;
    border-right: 1px solid  #e4e4e4
}

.fb-fgsres-table-body-m .data{
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 2%;
}

.fb-fgsres-table-body-m .color-red {
    color: rgb(222, 53, 11)
}
    }
}