@import '../common-mixin.scss';

$boxShadow : 0 3px 5px rgba($color: (#000), $alpha: 0.05);

.FB header.RESULTS {
    display: none;
}

.fb-results-header {
    display: none;
}

.fb-results-footer {
    display: flex;
    margin: 12px 0;
    padding: 12px 0 ;
    white-space: pre-line;
    line-height: 18px;
    @include font11();
    font-weight: 500;
    border-top: 1px solid #b4b4b4;
}


.fb-results-footer img {
    width: 28px;
    margin-right: 15px;
}

.mobile-top-title, .tourn-result-mobile-title {
    display: none;
}

.fb-results-match-results-m,
.fb-results-mip-table-m {
    display: none;
}

.fb-results-footer .payout-time-link {
    color: #405A55;
    cursor: pointer;
}

.fb-results-footer .payout-time-link:hover {
    text-decoration: underline;
}

.fb-results-mip-table-container {
    width: 100%;
    margin-bottom: 15px;
}

.fb-results-mip-table {
    width: 100%;
    display: table;
    box-shadow: $boxShadow;
}

.fb-results-mip-table .table-row:not(.table-header) {
    background: #fff;
    min-height: 44px;
    height: 44px;
}

.fb-results-mip-table .table-row.table-header {
    height: 30px;
    @include font11();
}

.fb-results-mip-table .table-row.sec {
    background: #f4f4f4;
}
.fb-results-mip-table .table-row>*{
    vertical-align: middle;
    padding: 4px 8px;
    position: relative;
}
.fb-results-mip-table .table-row:last-child >div:first-child{
    border-radius: 0 0 0 4px;
}
.fb-results-mip-table .table-row:last-child >div:last-child{
    border-radius: 0 0 4px 0;
}

.fb-results-mip-table .table-row>div:not(.had-name, .date-cell) {
    text-align: center;
}

.fb-results-mip-table .table-row:not(.table-header) > *:not(:last-child)::before {
    content: '';
    width: 1px;
    height: 80%;
    position: absolute;
    background: #e4e4e4;
    top: 10%;
    right: 0;
}

.fb-results-mip-table .table-row>.last-odds {
    position: initial;
    width: 58px;
    padding: 4px 12px;
}

.fb-results-mip-table-container .last-odds div {
    margin: 0 auto;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEBEB;
    color: #00C06C;
    font-size: 17px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}

.fb-results-mip-table-container .detail-btn {
    background: url(../../info/Football/info/images/football_result_detail_icon.png) no-repeat #EBEBEB;
    background-size: 20px 20px;
    background-position: center center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    margin: 0 auto;
    cursor: pointer;
}

.fb-results-mip-table-container .detail-btn.disable-detail-btn {
    opacity: 0.5;
    cursor: default;
}

.fb-results-match-results .table-header {
    width: 100%;
    height: 42px;
    display: flex;
}

.fb-results-match-results .table-header>div {
    display: flex;
    align-items: center;
    @include font11();
}

.fb-results-match-results .table-header>.full-time-cell {
    flex-direction: column;
}

.fb-results-match-results .full-time-cell span:nth-of-type(2) {
    display: block;
    padding-top: 2px;
}

.fb-results-match-results .table-header>div:not(.had-name, .date-cell) {
    justify-content: center;
    text-align: center;
}

.fb-results-match-results .date-cell {
    width: 92px;
}

.fb-results-match-results .matchNo-cell {
    width: 72px;
}

.fb-results-match-results .flag-cell {
    width: 48px;
}

.fb-results-match-results .had-name {
    flex: auto;
    word-break: break-word;
}

.fb-results-match-results .half-time-cell {
    width: 100px;
    position: relative;
}

.fb-results-mip-table-container span.full-time,
.fb-results-mip-table-container .half-time span {
    @include font15();
}

.fb-results-match-results .full-time-cell {
    width: 100px;
}
.fb-results-match-results .void-half-time{
    span{
        width: 200px;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
    }
    &::before{
        content: '';
        position: static !important;
    }
}


.fb-results-match-results .detail-cell {
    width: 58px;
}

.fb-results-match-results .last-odds-cell {
    width: 70px;
}

.fbMain .RESULTS header, .fbMain .RESDTLS header {
    background: #405A55;
    min-height: 32px;
    border-radius: 4px 4px 0 0;
    justify-content: flex-start;
    align-items: center;
    color: #FFF;
}

.fbMain .RESULTS .fb-search-title{
    background: #ffffff;
    color: #333333;
    height: 48px;
    border-bottom: 1px solid #E7E7E7;
    cursor: pointer;
}
.fbMain .RESULTS .fb-search-title.searchBoxExpand{
    border-radius: 0 0 4px 4px;
}
.fbMain .RESULTS header .arrow-area{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #569171;
    margin: 0 12px;
    border-radius: 4px;
    cursor: pointer;
}
.fbMain .RESULTS header .arrow-area .arrow-small{
    border: solid rgb(255, 255, 255);
    border-width: 0 2px 2px 0;
}
.fbMain .RESULTS header .arrow-area .arrow-down{
    position: relative;
    top: -2px;
}
.fbMain .RESULTS header .arrow-area .arrow-up{
    position: relative;
    top: 2px;
}
.fbMain .RESULTS header .m-arrow-area{
    display: none;
}
.fb-results-match-results .MRTable-container {
    margin-bottom: 15px;
}

.fb-results-match-results .MRTable-container header {
    cursor: pointer;
}

.fbMain .RESULTS .expand {
    width: 20px;
    height: 20px;
    position: relative;
    background-color: #569171;
    border-radius: 4px;
    margin: 0 10px;
}

.fbMain .RESULTS .expand::after {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    top: 9px;
    left: 5px;
    background-color: #fff;
}

.fbMain .RESULTS .expand.close::before {
    content: '';
    width: 2px;
    height: 10px;
    position: absolute;
    top: 5px;
    left: 9px;
    background-color: #fff;
}

.fb-results-mip-table-container .void-half-time {
    color: #FF0000;
    position: relative;
    text-align: center;
}


.fb-results-mip-table-container .extra-time-red {
    white-space: nowrap;
    color: #FF0000;
}

.fb-results-mip-table-container .extra-time-orange {
    white-space: nowrap;
    color: orange;
    @include font15();
}

.fb-results-mip-table img, .chpres-table img {
    width: 24px;
    height: 21px;
}



.fbMain .RESULTS .dropdown-toggle {
    width: 100%;
    height: 32px;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    box-shadow: none;
    align-items: center;
    padding: 0 12px;
    background-color: #fff;
    color: #333;
    border: none;
    @include font13();
}

.fbMain .RESULTS .dropdown-toggle::after {
    content: none;
}

.fbMain .RESULTS .dropdown-toggle:hover {
    border: none;
}

.fbMain .RESULTS .btn-primary:active,
.fbMain .RESULTS .show>.btn-primary.dropdown-toggle {
    color: #333;
    background-color: #fff;
    border: none;
    box-shadow: none !important;
}

.fbMain .RESULTS button.search-btn {
    color: #ffffff;
    width: 100px;
    height: 28px;
    border: 0px;
    border-radius: 15px;
    @include font13();
}

.fbMain .RESULTS button.search-btn:hover {
    opacity: .8;
}

.fbMain .RESULTS .matchDropdown-ddbtn .dd-arrow {
    margin-top: -3px;
}

.fbMain .RESULTS .matchDropdown-ddbtn .select-value {
    display: flex;
    width: 90%;
    justify-content: space-between;
    line-height: 18px;
}

.fb-results-searchbox {
    background-color: #FFF;
    border-radius: 0 0 4px 4px;
    margin-bottom: 15px;
    box-shadow: $boxShadow;
}

.fb-results-searchbox>div {
    padding: 15px 12px 15px 12px;
}

.RESULTS .fb-results-searchbox-body{
    height: auto;
    &.searchbox-body-hide{
        height: 0;
        overflow: hidden;
        padding: unset;
    }
}
.FBFilterSelectDrd .dropdown-toggle{
    cursor: pointer;
}
.FBFilterSelectDrd .dropdown-menu.show{
    padding: 0;
    border-radius: 10px;
    overflow-x: unset;
    overflow-y: unset;
    max-height: 515px;
    transform: translate(0px, 34px) !important;
    max-width: 100%;
    & > div:first-child {
        background-color: #405A55;
        color: #ffffff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: left;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        font-size: 18px;
        line-height: 19px;
        font-weight: 500;
        &::before{
            content: "";
            position: absolute;
            top: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px;
            z-index: 9999;
            border-color: transparent transparent #405A55;
            right: 15%;
        }
    }
    .noResults::before{
        content: none;
    }
    .resultSearchText{
        position: relative;
        .clearSearchResultBtn{
            position: absolute;
            right: 12px;
            top: 32%;
        }
    }
    .fbSearchInput{
        width: 100%;
        height: 36px;
        padding-left: 12px;
        border: unset;
        @include H2();
        font-weight: 400;
        caret-color: #00C06C;
        &::placeholder,
        &::-khtml-input-placeholder,
        &:-moz-input-placeholder,
        &::-ms-input-placeholder,
        &:-webkit-input-placeholder{
            color: #dfdbdb;;
        }
    }
    .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: unset;
        outline: 0;
        box-shadow: unset;
    }
    .filterItem{
        cursor: pointer;
    }
    .dropdown-item {
        border-top: 1px #d2d2d2 solid;
        padding: 0 12px;
        height: 36px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;

        color: #333333;
        &:hover{
            color: #00C06C;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .dropdown-item.active{
        color: #00C06C;
        background-color: transparent;
    }
}

.fb-results-searchbox .fb-results-searchbox-content {
    display: flex;
    align-items: flex-end;
    max-width: 828px;
    margin: unset;
}
.fb-results-searchbox .fb-results-searchbox-form{
    display: flex;
    width: calc(100% - 100px);
    flex-direction: column;
    .searchbox-tips{
        margin-bottom: 9px;
    }
    .section-SEARCH{
        margin: 6px 0;
    }
    .date_range_football .date_range_picker{
        margin-top: 0;
        margin-bottom: 0px;
    }
}
.fb-results-searchbox .fb-results-searchbox-select{
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.fb-results-searchbox .date_range_football,.fb-results-searchbox .matchDropdown-ddbtn {
    margin: 0;
    margin-right: 22px;
    width: calc(50% - 22px);
}
.fb-results-searchbox .date_range_football .matchDropdown-ddbtn{
    width: 100%;
}

.fb-results-searchbox .FBFilterSelectDrd{
    position: relative;
}
.fb-results-searchbox .date_range_football > :first-child{
    white-space: nowrap;
    margin-bottom: 12px;
}
.fb-results-searchbox .reactRadio{
    margin-right:25px  ;
}
.fbResultsMatch-ddbtn{
    height: 34px;
}

.searchBoxRadioContainer{
    height: 32px ;
    align-items: center;
}

.fb-results-searchbox .date-picker-container .date-dd-arrow{
    position: absolute;
    right: 0px;
    top: 5px;
}
.fb-results-searchbox .date-picker-container .date-input{
    width: 100%;
    padding: 0 30px 0 0px;
    height: 34px;
    border-radius: 2px
}

.fb-results-searchbox-teamName {
    text-overflow: ellipsis;
    width: 80%;
    overflow: hidden;
    text-align: right;
}

.fb-result-detail-page .searchBtn-container {
    display: none;
}
.top-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 4px;
    height: 52px;
    background-color: #F4F4F4;
    border-radius: 0 0 4px 4px;
    margin-bottom: 15px;
}
.top-header-container .back-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.fb-result-detail-container .results-match-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    height: 60px;
}
.results-match-info .team-score{
    display: flex;
    @include font15();
    font-weight: 500;
    width: 60%;
    justify-content: space-between;
}
.results-match-info .team-score >div{
    margin:  0 10px;
    display: flex;
    width: 55px;
    justify-content: space-between;
    align-items: center;
}
.results-match-info .team-score >span{
    min-height: 16px;
    line-height: 16px;
    width: 40%;
    display: flex;
    align-items: center;
}
.results-match-info .team-score >span:first-child{
    text-align: right;
    justify-content:flex-end;
}
.results-match-info .team-score >div>span{
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: #FFD505;
    text-align: center;
    line-height: 24px;
    @include font13();
}
.fb-result-detail-container header.normal-header{
    display: flex;
    padding-left: 15px;
}
.fb-result-detail-container .matchResultTable-header{
    display: flex;
    padding-left: 15px;
}
.fb-result-detail-container .matchResultTable-header>div{
    flex: 1;
}
.fb-result-detail-container .results-detail-results{
    background-color: #FFF;
    margin-bottom: 15px;
    border-radius: 4px;
    overflow: hidden;
}
.fb-result-detail-container .results-detail-results >div{
    display: flex;
    width: 100%;
}

.results-spc-table > div >div {
    height: 100%;
    padding: 8px 0px 8px 12px;
}

.results-detail-results >div>div{
    // flex: 1;
    display: flex;
    min-height: 28px;
    padding-left: 12px;
    align-items: center;
    width: 25%;
}

.multiple-results {
    justify-content: center;
    flex-direction: column;
}

.multiple-results > div {
    align-self: flex-start;
}

.results-spc-table > div >div:not(:last-child),
.results-detail-results >div>div:not(:last-child){
    position: relative;

}

.fb-result-detail-container .results-detail-results .score-row {
    border-bottom: 1px solid #e4e4e4;
}
.fb-result-detail-container .results-detail-results .corner-row{
    background-color: #F4F4F4;
}
.matchResultTable{
    width: 100%;

    margin-bottom: 15px;
    border-radius: 4px;
    overflow: hidden;
}
.matchResultTable >div {
    display: flex;
}
.matchResultTable >div>div{
    /* flex: 1; */
    width: 50%;
}
.matchResultTable >div>div>div{
    display: flex;
    background: #FFF;
    border-bottom: 1px solid #e4e4e4;
}
.matchResultTable >div>div>div:last-child{
    border-bottom: none;
}

.RESDTLS .fb-result-detail-container  .mobile-show {
    display: none;
}

.matchResultTable >div>div>div>div{
    flex: 1;
    padding: 6px 0px 6px 12px;
    min-height: 28px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.fb-result-detail-container .sec {
    background-color: #F4F4f4;
}

.fb-result-detail-spc{
    background-color: #FFF;
}

.fb-result-detail-spc .result-detail-refund {
    display: flex;
    align-items: center;
}

.results-spc-table > div{
    display: grid;
    grid-template-columns: 15% 60% 25%;
    align-items: flex-start;
    min-height: 28px;
}


.results-spc-table > div .item-name{
    padding-right: 8px;
}
.matchResultTable .resultTableLeft>div{
    border-right: 1px solid #e4e4e4;
}
.matchResultTable header.matchResultTable-header {
    border-radius: 0;
}

.SPC-REFUND{
    color: red;
}
.fb-result-detail-container .searchBtn-container{
    display: none;
}
.fb-result-detail-container .footer-remark{
    margin-top: 20px ;
}

.fb-result-detail-container .frontendid-m{
    display: none;
}

.fbOddsTable section.LAST_ODDS {
    background-color: #fff;
    border-radius: 4px;
}

.fbOddsTable section.LAST_ODDS .team {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    height: 30px;
    width: 100%;
    line-height: 19px;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
    &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.ECD{
        grid-template-columns: 50% 50%;
    }
}


.fbOddsTable section.LAST_ODDS .supplement {
    display: none;
}

.fbOddsTable section.LAST_ODDS .match-row-container>.match-row-m.matrix:nth-child(odd) {
    background-color: #f4f4f4;
}

.fbOddsTable section.LAST_ODDS .match-row-container>.match-row-m.matrix:nth-child(even) {
    background-color: #fff;
}

.fbOddsTable section.LAST_ODDS .oddsHAD,
.fbOddsTable section.LAST_ODDS .oddsEHA,
.fbOddsTable section.LAST_ODDS .oddsFHA,
.fbOddsTable section.LAST_ODDS .oddsHHA,
.fbOddsTable section.LAST_ODDS .oddsFTS,
.fbOddsTable section.LAST_ODDS .oddsNTS,
.fbOddsTable section.LAST_ODDS .oddsENT {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    line-height: 32px;
    width: 100%;
    text-align: center;
    /* background-color: #f4f4f4; */
    justify-items: center;
    background-color: #fff;
}

.fbOddsTable section.LAST_ODDS .oddsFTS {
    background-color: #fff;
}

.fbOddsTable section.LAST_ODDS .oddsLine {
    display: grid;
    grid-template-columns: 33% 67%;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;
    // line-height: 32px;
    width: 100%;
}

.fbOddsTable section.LAST_ODDS .oddsLine:nth-child(even) {
    background-color: #f4f4f4;
}

.fbOddsTable section.LAST_ODDS .oddsLine .odds {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    justify-items: center;
}

.fbOddsTable section.LAST_ODDS .coupon .right {
    display: none;
}

.fbOddsTable header.LAST_ODDS .searchBtn-container {
    display: none;
}

.fbOddsTable section.LAST_ODDS .match-info {
    padding-bottom: 12px;
}

.fbOddsTable section.LAST_ODDS .match-info>div {
    align-items: center;
}


.fbOddsTable header.LAST_ODDS .match-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 4px 6px 22px;
}

.fbMain .RESULTS header.LAST_ODDS {
    display: block;
    margin-bottom: 10px;
}

.fbMain .RESULTS header.LAST_ODDS .back-btn {
    color: #333;
    cursor: pointer;
}

.fbMain .RESULTS header.LAST_ODDS .back-btn .dd-arrow{
    margin-right: 8px;
}

.fbMain .RESULTS .resultOpenTitle{
    padding-left: 0;
}
.fbMain .RESULTS .resultOpenTitle #lblPageName{
    align-items: center;
}
.fbMain .RESULTS .resultOpenTitle .expand{
    cursor: pointer;
}

.fbOddsTable section.LAST_ODDS .odds.oddsTTG .oddsCheckbox,
.fbOddsTable section.LAST_ODDS .odds.oddsETG .oddsCheckbox,
.fbOddsTable section.LAST_ODDS .odds.oddsHFT .oddsCheckbox {
    display: none;
}

.fbOddsTable section.LAST_ODDS{
    .odds>div, .team.NTS .oddsCheckboxGrid, .team.ENT .oddsCheckboxGrid{
        min-width: auto;
        grid-template-columns: 34px;
    }
    .odds>.hdcOddsItem .oddsCheckboxGrid {
        min-width: auto;
        display: flex;
    }
}

.fbOddsTable section.LAST_ODDS .match-row-container.FGS .oddsCheckboxGrid {
    justify-self: center;
    align-items: flex-start;
}

.fbOddsTable section.LAST_ODDS .match-info .team {
    grid-template-columns: 33% 80px 33% 20px;
}

.fbOddsTable header.RESULTS .last-odds-refreshTime{
    display: none;
}
.fbOddsTable header.LAST_ODDS .last-odds-refreshTime{
    display: block;
}

.fbOddsTable header.LAST_ODDS .refresh-odds{
    display: none;
}

.fbOddsTable section.LAST_ODDS .team.FGS{
    grid-template-columns: 50% 50%;
}

.fbOddsTable section.LAST_ODDS .team.NTS,
.fbOddsTable section.LAST_ODDS .team.ENT,
.fbOddsTable section.LAST_ODDS .odds.oddsNTS,
.fbOddsTable section.LAST_ODDS .odds.oddsENT{
    grid-template-columns: 25% 25% 25% 25%;
}

.fbOddsTable section.LAST_ODDS .match-row-container .odds span:hover{
    cursor: unset;
    text-decoration: none;
}

.fbOddsTable section.LAST_ODDS .odds{
    -ms-user-select: text;
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
}


.fbOddsTable section.LAST_ODDS .odds .goal-number {
    display: block;
}

.noMatchResult {
    width:100%;
    padding: 100px 0px;
    text-align: center;
}

.RESULTS .fb-refresh-time, .RESDTLS .fb-refresh-time {
    display: none;
}

.LASTODDS .refresh-odds {
    display: none;
}

.matchResultTable .results-nts {
    padding: 8px 0px;
}

.matchResultTable .results-nts .results-nts-table {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px;
    align-items: flex-start;
}

.matchResultTable .results-nts .results-nts-table>div  {
    padding-left: 12px;
}

.matchResultTable .results-nts .results-nts-table>div:nth-child(2n+1) {
    border-right: 1px solid #b7b7b7;
}
.HaFuResultTableHeader > .header-left{
    display: flex;
    align-items: center;
}

.fbMain .MRTable-container .HaFuResultTableHeader{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding-right: 13px;
    margin-top: 15px;
}
.hafu-results-table{
     .fb-results-mip-table{
        .table-row{
            .table-cell-team{
                white-space: pre-wrap;
                text-align: start;
            }
            .table-cell{
                background-color:  #fff;
                padding: 4px 8px;
                min-height: 44px;
                .results-score{
                    display: grid;
                    grid-template-columns: 50% 50%;
                    align-items: center;
                    justify-content: space-around;
                    &.isMatchVoid{
                        grid-template-columns: 100%;
                    }
                    div {
                        white-space: nowrap;
                    }
                }
            }
            &.sec .table-cell{
                background-color: #F4F4F4;
            }
            .DHCRES-leg, .DHCRES-day{
                min-width: 80px;
                max-width: 80px;
            }
            // .DHCRES-day{

            // }
            .DHCRES-flag{
                min-width: 42px;
                max-width: 42px;
                width: 42px;
            }
            .DHCRES-team{
                min-width: 160px;
                max-width: 160px;
            }
            .DHCRES-date{
                min-width: 100px;
                max-width: 100px;
            }
            .DHCRES-score{
                min-width: 160px;
                max-width: 160px;
            }
        }
        .table-header{
            min-height: 44px;
            height: 44px;
            .table-cell{
                background-color:  #EAEAEA;
            }

            .table-cell-results{
                height: 100%;
                padding: 0 10px;
                height: 44px;
                &>div{
                    height: 50%;
                    display: flex;
                    align-items: center;
                }
                &>:first-child{
                    text-align: center;
                    justify-content: center;
                    border-bottom: 1px solid #b4b4b4;

                }
                &>:last-child{
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
     }
    .hafu-results-dividendInfo{
        display: flex;
        justify-content: space-evenly;
        height: 60px;
        align-items: center;
        background: #EBF5F0;
        &>div{
            flex: auto;
            height: 50px;
            padding: 6px 13px;
            span{
                display: block;
                padding: 8px 0 0;
                color: #405A55;
            }
        }
        &>:not(:last-child){
            border-right: 1px solid #b4b4b4;
        }
    }
}

.HFMRES .hafu-results-dividendInfo{
    height: 80px;
    &>div{
        height: 70px;
    }
}
// header.DHCRES, header.HFMRES{
//     display: none;
// }
.fbMain .RESULTS > header.DHCRES-MobileHeader{
    display: none ;
}
.void-color {
    color: #cc0000;
}

.searchTitle {
    padding-left: 4px;
}

section.TOURNRES >section{
    border-radius: 0 0 4px 4px;
    box-shadow: $boxShadow;
    overflow: hidden;
}

.fbOddsTable .LASTODDS .match-row .odds{
    font-weight: 400;
}