@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
header.CHPRES, header.TOURNRES {
    display: none;
}

section.TOURNRES .CHP, section.TOURNRES .TQL,
section.TOURNRES .GPW, section.TOURNRES .GPF,
section.TOURNRES .TPS, section.TOURNRES .TSP {
    font-size: 15px;
}

.fb-results-mip-table .table-row.table-header{
    display: none;
}
.fbMain .RESULTS >header{
    display: none !important;
}
.fbMain .RESULTS .fb-search-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    height: 40px;
    padding: 0 12px;
    font-size: 15px;
    font-weight: 500;
}
.fbMain .RESULTS header .arrow-area{
    display: none;
}
.fbMain .RESULTS header .arrow-area .arrow-small{
    margin-left: 0px;
    margin-bottom: 0px;
}
.fbMain .RESULTS header .m-arrow-area{
    display: block;
}
.fbMain .RESULTS header .m-arrow-area .dd-arrow {
    border: solid #333333;
    border-width: 0 2px 2px 0;
    width: 10px;
    height: 10px;
    position: relative;
    right: 4px;
    top: 4px;
    transition: all 0.3s;
}
.fbMain .RESULTS header .m-arrow-area .arrow-down{
    position: relative;
    top: 0px;
}
.fbOddsTable>section.RESULTS,
.fbOddsTable>section.RESDTLS,
.fbOddsTable>section.LASTODDS {
    width: 100vw;
    margin: 0;
}
.RESDTLS .mobile-top-title {
    grid-template-columns: 5% 95%;
}
.LASTODDS .mobile-top-title {
    grid-template-columns: 8% 27% 65%;
}
.RESDTLS .mobile-top-title,
.LASTODDS .mobile-top-title {
    display: grid;
    background-color: #333333;
    height: 52px;
    color: #FFF;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding-left: 8px;
}
.RESDTLS .mobile-top-title{
    gap: 8px;
}

.RESDTLS .mobile-top-title > .dd-arrow,
.LASTODDS .mobile-top-title > .dd-arrow {
    border-color: #FFF;
    width: 11px;
    height: 11px;
    margin-top: -2px;
    margin-right: 8px;
}
.RESULTS >div:not(.mobile-top-title):not(.fb-results-searchbox){
    /* width: calc(100vw - 16px); */
    /* margin: 0 8px; */
}
.tourn-result-mobile-title {
    width: 100%;
    display: block;
    background-color: #405a55;
    color: #FFFFFF;
    padding: 12px 0px 0px 16px;
    height: 40px;
    font-size: 15px;
}
.fb-results-header {
    display: flex;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    width: 100%;
    color: #FFFFFF;
    padding: 8px 12px;
    font-size: 15px;
}
.resultsTitlemobile{
    display: flex;
    background: #fff;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    border-radius: 4px 4px 0 0;
    margin: 0 8px;
}
.fbMain .RESULTS .resultsTitlemobile.MIPClose{
    margin-bottom: 15px;
    border-radius: 4px;
}
.resultsTitlemobile .dd-arrow{
    transition: all 0.3s;
    border-color: #333;
    margin: 0;
}
.resultsTitlemobile .dd-arrow.arrow-down{
    margin-top: -1px;
}
.resultsTitlemobile .dd-arrow.arrow-up{
    margin-top: 3px;
}
.resultInProgressTable{
    border-radius: 0 !important;
}
.fb-results-header .searchBtnContainer{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background: #757575;
}
.fb-results-header .searchBtnContainer.expanded{
    background: #00c06c;
}
.fb-results-header .searchBtnContainer.collapsed{
    background: #757575;
}
.fb-results-header .icon-search{
    margin: 0;
    width: 26px;
    height: 26px;
    background-size: cover;
}
.fbMain .fbMiddle .DHCRES .fb-results-footer,.fbMain .fbMiddle .HFMRES .fb-results-footer {
    margin: 15px 8px;
}
.lastodds-refresh-time {
    text-align: right;
    padding-right: 8px;
    font-size: 11px;
}

.fb-results-searchbox >div{
    padding-left:10vw ;
    padding-right:10vw ;
}
.fbMain .RESULTS .date-picker-container,
.fbMain .RESULTS .date-input{
    width: 100%;
}
.RESULTS .fb-results-searchbox{
    margin-bottom: 15px !important;
}
.fb-results-mip-table,
.fb-results-match-results{
    display: none;
}

.fb-results-match-results-m,
.fb-results-mip-table-m{
    display: table;
    width: 100%;
}
.fb-results-mip-table-m{
    margin-bottom: 12px;
    background-color: #FFF;
    width: calc(100vw - 16px);
    margin: 0px 8px 12px;
}
.fb-results-match-results-m .table-header-mobile,
.fb-results-mip-table-m .table-header-mobile{
    border-radius:4px 4px 0 0 ;
    font-size: 11px;
}
.RESULTS .table-header-mobile {
    background: #405A55;
    color: #FFF;
    height: 36px;
    vertical-align: middle;
}
.fb-results-mip-table-m .table-row .table-cell{
    vertical-align: middle;
}
.fb-results-mip-table-m .table-row .table-cell:first-child >div,
.fb-results-mip-table-m .table-header-mobile .table-cell:first-child{
    padding-left: 8px;
}

.fb-results-mip-table-m .table-row .table-cell:not(:first-child){
    text-align: center;
}
.fb-results-mip-table-m .result-detail,
.fb-results-mip-table-m .last-odds {
    width: 38px;
}
.fb-results-mip-table-m .first-scorer{
    width: 35% ;
}
.fb-results-mip-table-m .full-time-cell,
.fb-results-mip-table-m .half-time-cell{
    width: 48px;
}
.fb-results-mip-table-m .void-half-time{
    position: relative;
}
.fb-results-mip-table-m .detail-btn,
.fb-results-mip-table-m .last-odds div{
    width: 28px;
    height: 60px;
    margin: 0 auto;
}
.fb-results-mip-table-container .fb-results-mip-table-m .void-half-time span{
    width: 96px;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
}
.fb-results-mip-table-m  span.extra-time{
    display: block;
    margin-top: 4px;
}
.fb-results-mip-table-m .team-name{
    font-weight: 500;
    font-size: 15px;
}
.fb-results-mip-table-m .result-match-info {
    padding-left: 0;
    word-break: break-word;
}
.fb-results-mip-table-m .result-match-info > :first-child  {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 11px;
    line-height: 20px;
}
.fb-results-mip-table-m .result-match-info .match-date{
    height: 20px;
    width: 80px;
    font-size: 11px;
    line-height: 20px;
    position: relative;
    background-color: #E2E2E2;
}
.fb-results-mip-table-m .result-match-info  .match-date-triangle{
    border-left: 10px solid #e2e2e2;
    border-bottom: 10px solid #e2e2e2;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    position: absolute;
    right: -20px;
    top: 0;
    width: 20PX;
    height: 20PX;
}
.RESULTS .table-header-mobile:not(.resultInProgressTable) :first-child{
    border-radius: 4px 0 0 0;
}
.RESULTS .table-header-mobile:not(.resultInProgressTable) :last-child{
    border-radius: 0 4px 0 0;
}
.RESULTS .table-header-mobile .table-cell{
    padding: 0 4px;
}
.fb-results-mip-table-container .table-row:not(.table-header-mobile){
    height: 80px;
}
.fb-results-mip-table-container .sec{
    background-color: #F4F4F4;
}
.fb-results-searchbox-body{
    height: auto;
}
@keyframes searchboxHide {
    from{
        overflow: hidden;
        height: 190px;
    }
    to{
        height: 0;
    }
}
@keyframes searchboxShow {
    0% {
        overflow: hidden;
        height: 0;
    }
    98% {
        overflow: hidden;
        height: 190px;
    }
    100%{
        overflow: unset;
    }
}
.RESULTS .fb-results-searchbox-body{
    height: 190px;
    padding: 0px 10vw;
    &.searchbox-body-hide{
        padding: 0px 10vw;
        animation: searchboxHide 0.5s linear;
    }
    &.searchbox-body-show{
        animation: searchboxShow 0.5s linear;
    }
}
.fb-results-searchbox .matchDropdown-ddbtn, .fb-results-searchbox .date_range_football, .fb-results-searchbox-content>div{
    width: 100%;
    margin: 6px 0;
}

.fb-results-searchbox .fb-results-searchbox-content{
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0;
}
.fb-results-searchbox .fb-results-searchbox-form{
    width: 100%;
    .section-SEARCH{
        margin: 0;
    }
    .fbSearchInput{
        width: 100%;
    }
}
.fb-results-searchbox .fb-results-searchbox-select{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.searchBoxRadioContainer{
    width: 100%;
}
#SearchBox.fb-results-searchbox .fb-results-searchbox-content .search-btn{
    display: inline-block;
    width: 55%;
}

.fbMain .RESULTS .date-picker-container{
    margin: 6px 0;
}

.fb-result-detail-container .frontendid{
    display: none;
}
.fb-result-detail-container .results-match-info{
    display: block;
}
.fb-result-detail-container .top-header-container,
.fb-result-detail-container .fb-result-detail-header{
    display: none;
}
.fb-result-detail-container .frontendid-m{
    display: inline-block;
}
.RESULTS div.fb-result-detail-container{
    width: 100vw;
    margin:0;

}
.fb-result-detail-container .results-match-info {
    width: 100vw;
    margin:0;
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: #405A55;
}
.fb-result-detail-container .results-match-info > :last-child{
    font-size: 11px;
    margin: 8px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.fb-result-detail-container .results-match-info > :last-child>span{
    width: 40%;
}
.fb-result-detail-container .results-match-info > :last-child>span:first-child{
    text-align: right;
}
.fb-result-detail-container .results-match-info img{
    width: 21px;
    height: 21px;
    margin: 0 10px;
}
.fb-result-detail-container .fb-result-detail-spc,
.fb-result-detail-container  .footer-remark,
.fb-result-detail-container .matchResultTable,
.fb-result-detail-container .results-detail-results{
    width: calc(100vw - 16px);
    margin: 0 8px;
}

.results-detail-results >div>div{
    width: 50%;
}
.fb-result-detail-container  .footer-remark{
    margin: 15px 8px 0;
}

.mobile-hide {
    display: none !important;
}
.RESDTLS .fb-result-detail-container .mobile-show {
    display: block;
}
.RESDTLS .fb-result-detail-container .mobile-show:is(.score-row, .corner-row){
    display: flex;
}
.RESDTLS .fb-result-detail-container .corner-row:not(.mobile-show){
    background-color: unset;
}
.RESDTLS .fb-result-detail-container .corner-row.hasHalfTimeCorner{
    border-bottom: 1px solid #e4e4e4;
}
.RESDTLS .fb-result-detail-container header{
    border-radius: 0;
}
.fb-result-detail-container .results-detail-results{
    overflow: visible;
}
.fb-result-detail-full-time  .results-detail-results header{
    border-radius: 4px 4px 0 0;
}
.matchResultTable{
    border-radius: 0;
}
.matchResultTable >div>div{
    width: 100%;
}
.matchResultTable >div>div>div:last-child{
    border-bottom: 1px solid #e4e4e4;
}
.matchResultTable >div {
    display: block;
}

.matchResultTable .resultTableRight .sec{
    background-color: #F4F4F4;
}
.fbOddsTable section.LAST_ODDS{
    background-color: #dee2e6;
}

.fbOddsTable section.LAST_ODDS .match-info{
    background-color: #fff;
    margin: 0px 0px 10px 0px;
}


.fbOddsTable section.LAST_ODDS .coupon-m .odds{
    display: none;
}

.fbOddsTable section.LAST_ODDS>div:nth-child(2){
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.fbOddsTable section.LAST_ODDS>div:last-child .odds{
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fbOddsTable section.LAST_ODDS .coupon-m .desc{
    margin-left: 3px;
}
.fb-results-searchbox-content{
    width: 100%;
}
.results-match-info .team-score{
    width: 100%;
}
.fbOddsTable section.LAST_ODDS .match-info .team{
    grid-template-columns: 33% 80px 33%;
}

/* .FTCalculator{
    align-items: baseline;
}
.FTCalculator .ftCalcContainer{
    width: 100%;
    height: 385px;
}
.FTCalculator .ftCalcTitle{
    border-radius: 0;
    height: 52px;
    position: relative;
    font-size: 18px;
    text-align: center;
    line-height: 52px;
    font-weight: 700;
    width: 100vw;
}
.FTCalculator .ftCalcTitle>div:first-child{
    width: 100%;
}

.FTCalculator .ftCalcTitle .dd-arrow-left{
    border-color: #fff;
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    left: 14px;
    top: calc(50% - 10px);
}
.FTCalculator .ftCalcTitle .ftCalcIcon{
    display: block;
    position: absolute;
    width: 76px;
    height: 40px;
    background-color: #569171;
    right: 12px;
    bottom: -54px;
    border-radius: 20px;

    background-image: url(../../info/Football/info/images/ftCalcIcon.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.FTCalculator .ftCalcTitle .allUpComb{
    display: none;
}
.FTCalculator .ftCalcBetComb{
    flex-direction: column;
    height: auto;
    align-items: baseline;
    padding-bottom: 14px;
}
.FTCalculator .ftCalcBetComb .dfr{
    margin-top: 12px;
}
.FTCalculator .ftCalcBetComb .dfc{
    width: 96px;
    word-wrap: break-word;
    word-break: break-word;
}
.FTCalculator #ftBetTypeText,
.FTCalculator #formulaText,
.FTCalculator #unitbetrange{
    width: 180px;
    padding: 0 8px;
}
.FTCalculator .ftCalcSelection .ftCalTable{
    display: grid;
    grid-template-columns:  repeat(3, 88px);
    overflow-x: auto;
    overflow-y: hidden;
}
.ftCalcSelection .ftCalColFirst:before {
    content : "";
    position: absolute;
    top    : 0;
    right  : 0;
    height  : 100%;
    width   : 1px;
    border-right:1px solid #B4B4B4;
    box-shadow: 2px 0px 7px;
}
.FTCalculator .ftCalcSelection .dropdown-menu{
    max-height: 76px;
}
.FTCalculator .ftCalcBotArea .dfc{
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    line-height: 20px;
}
.FTCalculator .ftCalcBotArea .dfc .text{
    padding-left: 0px;
} */
header.DHCRES .date-competition-selection, header.HFMRES .date-competition-selection {
    display: none;
}
.fbOddsTable section.DHCRES header>.fbTitle{
    display: flex;
    padding-left: 17px;
}
.fbMain .RESULTS > header.DHCRES-Header{
    display: none;
}
.fbMain .RESULTS > header.DHCRES-MobileHeader{
    display: flex !important;
    border-radius: 0;
    font-size: 15px;
    padding: 0 12px;
    align-items: center;
}
.HafuResContainerMobile{
    padding-top: 15px;
}
.HafuResContainerMobile .HaFuResultTableHeader{
    padding-left: 8px;
}
.HaFuResultTableStopSellDate {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
}

.HafuResContainerMobile .hafu-results-table-m .hafu-results-dividendInfo > div{
    display: flex;
    min-height: 28px;
    background: #EBF5F0
}
.hafu-results-table-m .hafu-results-dividendInfo > div:not(:last-child){
    border-bottom: 1px solid #b4b4b4;
}
.hafu-results-dividendInfo > div > *{
    display: flex;
    align-items: center;
    width: 50%;
    padding-left: 12px;
    font-size: 15px;
}
.hafu-results-dividendInfo > div > div{
    position: relative;
}
.hafu-results-dividendInfo > div > div::before{
    content: '';
    background-color: #B4B4B4;
    width: 1px;
    height: 70%;
    top: 15%;
    position: absolute;
    right: 0;
}
.hafu-results-dividendInfo > div > span{
    color: #405A55;
}
.hafu-results-table-m-header{
    display: flex;
    background-color: #EAEAEA;
    height: 24px;
}
.HafuResTableRowMobile{
    display: flex;
    height: 80px;
    background: #fff;
}
.hafu-results-table-m .HafuResTableRowMobile:last-child{
    border-radius: 0 0 4px 4px ;
    overflow: hidden;
}
.HafuResTableRowMobile.sec{
    background-color: #F4F4F4;
}
.hafu-results-table-m-header>div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hafu-results-table-m-header > :not(:first-child), .HafuResTableRowMobile > :not(:first-child){
    width: 25%;
}
.hafu-results-table-m-header > :first-child,.HafuResTableRowMobile > :first-child{
    width: 50%;
}
.HafuResTableRowMobile > .DHCRES-void-m{
    width: 50%;
    color: #CC0000;
}
.HafuResTableRowMobile > :not(:first-child){
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
    font-size: 15px;
    font-weight: 500;
}
.HafuResTableRowMobile .DHCRES-info-m{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.HafuResTableRowMobile .DHCRES-info-m >div{
    padding: 0 6px;
}
.HafuResTableRowMobile .DHCRES-info-m >:first-child{
    height: 18px;
    display: flex;
    align-items: center;
}
.HafuResTableRowMobile .DHCRES-info-m .DHCRES-leg-m {
    font-size: 11px;
    margin-left: 15px;
}
.HafuResTableRowMobile .DHCRES-team-m{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
}
.HafuResTableRowMobile  .DHCRES-date-m{
    height: 20px;
    width: 80px;
    font-size: 11px;
    line-height: 20px;
    position: relative;
    background-color: #E2E2E2;
}

.HafuResTableRowMobile  .DHCRES-date-m .match-date-triangle{
    border-left: 10px solid #e2e2e2;
    border-bottom: 10px solid #e2e2e2;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    position: absolute;
    right: -20px;
    top: 0;
    width: 20PX;
    height: 20PX;
}
.HFMRES .hafu-results-dividendInfo{
    padding: 0;
}
.HFMRES .hafu-results-dividendInfo > div{
    height: auto;
    padding: 8px 0px;
}

#SearchBox .fb-search-title{
    display: none;
}
    }
}