.toast-fixed {
    position: fixed;
    width: 100%;
    min-width: 1024px;
    top: 0px;
    left: 0;
    right: 0;
    height: 0px;
    margin: 0 auto;
    z-index: 2000;
}

.toast-con {
    width: calc(100% - 24px) !important;
    margin: 0 auto !important;
}

@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion, #popup-root) {
        .toast-con {
            width: calc(100% - 16px) !important;
            margin: 0 auto !important;
        }
    }
    
    .toast-fixed {
        min-width: 375px;
    }
}

.left-content.reset {
    height: 0px;
}

.betslip-container.reset {
    height: 0px;
}


.toastResultMeassage {
    display: flex;
    color: #fff;
    align-items: center;
    padding: 0 15px;
    height: 35px;
    border-radius: 2px;
    margin-top: 20px;
    animation: msgAction var(--showTime) ease-out forwards;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    opacity: 1;
    &.liteWarning{
        background-color: #E2EDF6;
    }

    &.successful {
        // background-color: #3fa33a;
    }
    &.rejected {
        // background-color: #e10004;
    }
    span {
        margin-left: 15px;
        color: rgba(51, 51, 51, 1);
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        line-height: 15px;
    }
}
@keyframes msgAction {
    0%,
    80% {
        opacity: 1;
        height: 35px;
    }
    100% {
        opacity: 0;
        height: 0px;
    }
}

.icon-status-accepted {
    background-image: url(../../../../info/Include/images/status-success-green.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
}
.icon-status-warning {
    background-image: url(../../../../info/Include/images/warning_icon_blue.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
}
.icon-status-liteWarning{
    background-image: url(../../../../info/Lite/images/unknow_yellow.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
}
// .icon-status-rejected {
//     background-image: url(../../../../info/Include/images/status-failed.svg);
//     background-repeat: no-repeat;
//     background-size: cover;
//     width: 20px;
//     height: 20px;
// }
.toastMsgIcon {
    animation: msgIconAction var(--showTime) ease-out forwards;
    flex-shrink: 0;
}

.mobileToast{
    height: 0;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .toastResultMeassage{
        width: calc(100% - 16px);
        margin: 0 auto;
        margin-top: 40px;
        border-radius: 8px;
        opacity: 1;
    }
}
@keyframes msgIconAction {
    0%,
    80% {
        opacity: 1;
        height: 20px;
    }
    100% {
        opacity: 0;
        height: 0px;
    }
}
