@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
.M6Middel section {
  width: 100%;
}
#menu-icon::after {
  display: none;
}
.marksixMain{
  width: 100%;
  padding: 0;
}
.M6Middel {
  width: 100%;
}
.marksixMain .ball-panel-instructions {
  display: none !important;
}
.marksixMain .radio-container{
  padding-left: 15px;
}
.marksixMain .marksix-header-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  width: 100vw;
  background-color: #ffffff;
  height: 60px;
  font-size: 15px;
  margin:0 0 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
  border-bottom: 1px solid #b4b4b4;
}
.marksix-header-info-snowball{
  background-color: #FFF;
  color: #333;
}
.marksix-header-info .meeting-info-menu{
  display: flex;
  align-items: center;
  text-align: right;
}
.marksix-header-info .dropdown-menu a.meeting-info-menu-title{
  background-color: #980B0B;
  width: 100%;
  display: block;
  text-align: left;
  color: #FFF;
}
.marksixMain .meeting-info-menu .dropdown-item:first-child {
  background-color: #980B0B;
}
.marksixMain .meeting-info-menu .dropdown-menu {
  overflow: hidden;
  border-radius: 10px;
  background-color: #980B0B;
}
.marksixMain .marksix-header-info>div{
  padding: 0 0 0 12px;
}
.marksixMain .marksix-header-info .marksix-info {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 60%;
}
.marksixMain .marksix-header-info .marksix-info::after {
  content: '';
  width: 1px;
  height: 46px;
  position: absolute;
  background-color: #b4b4b4;
  right: 0;
  // top: 5px;
}
.marksixMain .marksix-header-info .marksix-info-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 40%;
}

.LY .bottom-menu .bottom-menu-link.active {
  color: #D62D2D;
  img {
    // filter: drop-shadow(24px 0 0 #D62D2D);
    // position: relative;
    // left: -24px;
    // border-right: solid transparent  1px;
    // box-sizing: content-box;
    filter: brightness(0) saturate(100%) invert(16%) sepia(71%) saturate(6189%) hue-rotate(354deg) brightness(94%) contrast(77%);
   }
}
.marksixMain .marksix-random-middle .amount-diplay-m,
.marksixMain .marksix-banker-middle .amount-diplay-m,
.marksixMain .marksix-multiple-middle .amount-diplay-m,
.marksixMain .marksix-single-middle .amount-diplay-m {
  display: flex ;
  width: 100vw;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #ccc;
  border-top: unset;
}
.active .amount-diplay-m {
    box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
}
.marksixMain .marksix-random-middle .amount-diplay-d,
.marksixMain .marksix-banker-middle .amount-diplay-d,
.marksixMain .marksix-multiple-middle .amount-diplay-d,
.marksixMain .marksix-single-middle .amount-diplay-d {
  display: none ;
}
.marksixMain .marksix-checkresult-middle .amount-diplay-d{
  display: flex;
  max-width: calc(100vw - 12px);
  margin: 0 6px;
}
.ball-panel-table-container {
  width: 100vw;
  margin: 0;
  border-radius: 0;
  box-shadow:  var(--box-shadow);
  padding: 0;
}
.quick-pick-top-container{
  margin-bottom: 0;
  border-radius: 0;
  width: 100vw;
}
.quick-pick-top-container>header,
.ball-panel-table-container>header {
  display: none;
}
.ball-panel-table-container .ball-panel-table{
  margin: auto;
  padding: 8px 0;
}
.ball-panel-table-container .ball-panel-table>div>.table-cell>div{
  width: 42px;
  height: 42px;
  /* line-height: 36px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.STATISTICS{
  .ball-panel-table > div > div > div{
      &:nth-child(2) {
          margin-top: -2px !important;
      }
  }
}
.ball-panel-table-container .ball-panel-table>div>.table-cell>.table-cell-ball{
  /* padding-top: 3px; */
}
.ball-panel-table>div>div>div{
  margin: 3px;
}

.ball-panel-table-container .ball-panel-table>div>.table-cell,
.ball-panel-table-container .ball-panel-table>div>.table-cell>.table-cell-ball,
.ball-panel-table-container .ball-panel-table img {
  width: 48px;
  height: 48px;
}
.amount-display-btn{
  display: none !important;
}
.amount-display-table .amount-display-multdraw select {
  width: 105px !important;
}
.marksix-single-middle{
  width: 100vw;
  overflow: visible;
}
.amount-display-table-sb{
  justify-content: space-around;
  border-top: 1px solid #ccc;
}
.single-select-table{
  max-width: calc(100vw - 12px);
  margin: 0 6px;
  border-radius: 0 0 4px 4px;
  background-color: transparent;
}
.CHECKDRAWRESULT .single-select-table{
  margin-top: 15px;
  border-radius: 0 ;
}

.CHECKDRAWRESULT .amount-display-table .amount-display-btn{
  display: flex !important;
  &> :last-child{
    display: none;
  }
}
.CHECKDRAWRESULT .amount-display-btn .AddToSlip{
  display: none;
}
.single-select-table > .table-row > .table-cell {
  max-height: 48px;
}
.single-select-table > .table-row {
  background-color: #FFF;
}
.CHECKDRAWRESULT .single-select-table > .table-row {
  height: 60px;
}
.marksix-banker-middle .single-select-table .table-cell-banker-text{
  line-height: 36px;
  padding-left: 15px;
  min-width: 60px;
  margin-right: 6px;
  margin-left: unset;
}
.marksix-banker-middle .ball-panel-table-container{
  padding: 0 0;
}
.marksix-banker-middle .ball-panel-table-container .banker-selected-area{
  padding: 0 0;
  margin-bottom: 8px;
}
.marksix-banker-middle .banker-selected-area .banker-selected-text{
  width: 50%;
}
.my-favourites-table-container{
  width: calc( 100vw - 12px);
  margin: 12px 6px 0;
}
.my-favourites-table-container .my-favourites-table .table-row .table-cell{
  min-height: 48px !important;
  text-align: start;
}
.my-favourites-table-container .dropdown-toggle{
  width: 50px;
  padding: 0 4px;
}
.M6Middel .my-favourites-table-container .dropdown-menu{
  min-width: 60px;
}
.single-select-table .table-row-header>div{
  background-color: #EAEAEA;
}
.my-favourites-table-container .my-favourites-table .table-header>.table-checkbox-cell{
  min-width: 40px;
}
.my-favourites-table > .table-row > .table-cell {
  padding: 6px 0!important;
}
.my-favourites-table-container .AddToSlip-container{
  display: none;
}
.my-favourites-table .table-header :nth-child(2){
  padding-left: 10px;
}
.single-select-table .table-row .table-cell-fav{
  width: 50px;
  padding: 7px 0 7px 7px;
}
.single-select-table > .table-row > .table-cell .table-cell-fav-box{
  height: 36px;
  width: 50px;
}
.my-favourites-table> .table-row > .table-cell .ball-img-box,
.single-select-table > .table-row > .table-cell .ball-img-box{
  overflow: hidden;
  width: 32px;
  height: 36px;
}
.single-select-table .table-row-header .table-cell-number{
  padding-left: 0;
}
.single-select-table .table-row-header .table-cell-number >div{
  margin-left: 15px;
  height: auto;
}
.single-select-table .table-cell-ball-box{
  padding-left: 0px;
}

.single-select-table .table-cell-ctrl-box>div,
.my-favourites-table .table-row .table-cell-ball-box,
.single-select-table .table-cell-ball-box {
  min-height: 36px;
  height: auto;
  width: 100% ;
  padding: 0;
}
.CHECKDRAWRESULT .single-select-table .padLR8{
  padding-left: 8px;
  padding-right: 8px;
}
.single-select-table  .table-row .table-cell-bet-box {
  min-height: auto !important;
  margin-bottom: 4px;
}
.my-favourites-table .table-row .table-cell-ball-box,
.my-favourites-table .table-row .table-cell-checkbox>div{
  min-height: 36px;
  height: auto;
  border: none;
}

.my-favourites-table .table-row .table-cell-chance{
  width: 48px;
  padding-left: 10px !important;
  text-align: center;
}
.my-favourites-table .table-row .table-cell-multdraw{
  width: 50px;
}
.my-favourites-table .table-row .table-cell-multdraw select {
  width: 50px;
  height: 28px;
}
.my-favourites-table .table-row .table-cell-delete{
  width: 40px;
}
.my-favourites-table .table-row .table-cell-delete>div{
  min-height: 36px;
  height: auto;
}
.next-draw-table-container {
  width: calc(100vw - 16px);
  margin: 0 8px ;
}
.next-draw-table-container:last-child {
  margin-top:15px;
}

.next-draw-table {
  display: block;
  font-size: 15px;
  width: 100%;
}

.next-draw-table-r {
  width: 100%;
  background: #FFF;
}

.next-draw-table-header .next-draw-table-l{
  border-right: unset;
  .next-draw-table-item{
    width: calc(100% - 24px);
    margin-left: 12px;
    div{
      padding: 0px;
      flex: 0 0 50%;

    }
    &> :first-child{
      padding-right: 8px;
    }
  }
  .next-draw-table-item-no-bottom-boreder{
    padding-bottom: 16px;
    border-bottom: 1px solid #e9ecef;
  }
}
.next-draw-table-estAmount {
  .estFontSize,
  .estLabel{
    padding-top: 12px;
  }
}

.next-draw-margin-m {
  margin-top: 8px;
  border-radius: 4px;
}

.next-draw-table-sml-item.dtl {
  display: none;
}

.next-draw-table-result-balls>div, .next-draw-table-result-plus {

}

.next-draw-table-result-plus {
  margin: 0px 2px 0px 2px;
}

.quick-pick-top-container .ramdom-selection-panel{
  height: 125px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #ccc;
  gap: 16px;
}
.ramdom-selection-panel > :first-child {
  justify-content: flex-start;
  margin-top: 12px;
  width: 100%;
}
.ramdom-selection-panel > :first-child >div{
  width: 33%;
  padding: 0;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ramdom-selection-panel > :first-child >div>div{
  width: 110px;
}
.ramdom-selection-panel > :last-child {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  .m6_random_reset{
    font-size: 13px;
    font-weight: 500;
    color: #980B0B;
    text-decoration: underline;
  }
  button{
    margin-right: unset;
  }
}
.my-favourites-table .table-cell-ball-box > div .banker-label,
.single-select-table .random-ball-box > div .banker-label {
  min-width: 50px;
  height: 38px;
}
.marksix-random-middle .single-select-table .table-cell-ctrl-box{
  width: 40px;
}
.single-select-table .table-row:hover {
  background-color: #fff;
}
.M6Middel .single-select-table .table-row-header,
.M6Middel .single-select-table .table-row-header .table-cell{
  background-color: transparent;
}

.marksix-calculator-container{
  align-items: flex-start;
  min-width: unset;
  min-height: unset;
  width: 100vw;
  height: 100vh;
}
.marksix-calculator-container .marksix-calculator{
  border-radius: 0 0 4px 4px;
}
.marksix-calculator-container .marksix-calculator header{
  height: 65px;
  border-radius: 0;
  text-align: center;
  font-size: 18px;
  line-height: 65px;
}
.marksix-calculator .m6-cal-amount>div{
  text-align: center;
}

.marsix-chance-help-m{
  display: block;
  background-color: #FFF;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.marsix-chance-help-m .marsix-chance-help{
  align-items: center;
  height: 30px;
  font-size: 11px;
}
.marksix-random-middle .marsix-chance-help-m{
  box-shadow: none;
  border-bottom: solid 1px #ccc;
  border-top: none;
}

.statisticsPanel{
  height: 110px;
  padding: 11px 12px 13px 11px;
  justify-content: space-between;
  align-items: center;
}
.statisticsPanelL{
  width: calc((5/9) * 100%);
  margin-top: 11px;
}

.statisticsPanel .panelInfo{
  flex-direction: column;
}
.statisticsPanel .panelInfo>div{
  margin-right: 0px;
  margin-bottom: 15px;
}
.statisticsPanel .chooseModelBtn{
  width: 140px;
  height: 37px;
}
.statisticsPanel .chooseModelBtn div{
  width: 70px;
  line-height: 14px;
}
.STATISTICS .statFootnotes{
  padding-bottom: 15px;
}
.marksix-result-detail {
  background-color: transparent;
}
.RESULTS .marksix-results-table-container header{
  // display: none;
}
.CHECKDRAWRESULT .result-header-m,
.FIXTURES .result-header-m,
.STATISTICS .result-header-m,
.RESULTS .result-header-m,
.marksix-result-detail header{
  border-radius: 0;
  height: 52px;
  position: relative;
  font-size: 18px;
  text-align: center;
  line-height: 52px;
  font-weight: bold;
  width: 100vw;
}
.CHECKDRAWRESULT .result-header-m,
.FIXTURES .result-header-m,
.STATISTICS .result-header-m,
.RESULTS header.result-header-m{
  display: flex;
  justify-content: center;
}
.RESULTS header.result-header-m{
  justify-content: flex-start;
  padding-left: 17px;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}
.marksix-result-detail header .dd-arrow{
  border-color:#fff ;
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  left: 14px;
  top: calc( 50% - 10px);
}
.marksix-result-detail header>div:first-child{
  width: 100%;
}
.marksix-result-detail .draw-info {
  display: none;
}
.marksix-result-detail .draw-info-m{
  width: calc( 100vw - 12px);
  margin: 0 6px;
  display: block;
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 15px;
}
.marksix-result-detail .draw-info-table{
  display: table;
  width: 100%;
}
.marksix-result-detail .draw-info-table > .table-row >div{
  display: table-cell;
  width: 50%;
  padding-left: 12px;
  position: relative;
  height: 28px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}
.marksix-result-detail .draw-info-table > .table-row:last-child >div{
  border: none;
}
.marksix-result-detail .draw-info-table > .table-row:nth-child(2){
  background: #f4f4f4;
}
.marksix-result-detail .draw-info-table > .table-row >div:first-child::before{
  content: '';
  width: 1px;
  height: 70%;
  position: absolute;
  background-color: #ccc;
  right: 0;
  top: 15%;
}
.result-detail-r .table-row:nth-child(3){
  background-color: #F2ECEC;
}

.marksix-result-detail .draw-result {
  width: calc(100vw - 12px);
  margin: 15px 6px 0;
  border-radius: 4px;
  background: #FFf;
}
.marksix-result-detail .draw-result > div:first-child{
  height: 32px;
  border-bottom: 1px solid #b4b4b4;
  line-height: 32px;
}
/* .marksix-result-detail .draw-result .result-ball-list img */
.marksix-result-detail .draw-result .result-ball-list div{
  width: 48px;
  height: 48px;
  margin-left: -4px;
}
.marksix-result-detail .draw-result .result-ball-list{
  margin-top: 5px;
  padding-bottom: 20px;
  padding-right: 12px;
}
.marksix-result-detail .draw-result-table{
  display: block;
  width: calc(100vw - 12px);
  margin: 15px 6px 0;
  background: #FFF;
  border-radius: 4px;
  overflow: hidden;
}
.marksix-result-detail .draw-result-table > div{
  width: 100%;
}
.marksix-result-detail .draw-result-table .result-detail-r .table-header{
  display: none;
}
.marksix-result-detail .draw-result-table .result-detail-l .empty-row{
  display: none;
}
.marksix-result-detail .draw-result-table .result-detail-r{
  background-color: #F2ECEC;
}
.result-detail-r .table-row >div:first-child{
  border-left: none;
}
.RESULTS .results-remark{
  border: none;
  width: calc(100vw - 12px);
}
.RESULTS .result-lucky-number {
  width: 100vw;
  margin:  0;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  display: block;
  height: auto;
  height: 126px;
}
.result-lucky-number>div:last-child{
  width: 100%;
  justify-content: center;
  margin-top: 12px;
}
.RESULTS .result-search-box{
  width: 100vw;
  height: auto;
}
.result-search-box .draw-number-date-content {
  display: block;
}
.marksixMain .RESULTS .radio-container{
  padding-left: 24px;
}
.result-search-box .draw-number-date-content >div:last-child{
  margin-top: 28px;
}
.result-search-box .result-draw-type > div:last-child >:first-child >div{
  width: 80px;
}
.result-search-box .result-draw-type > div:last-child{
  align-items:flex-end ;
}
.result-search-box .result-draw-type button{
  height: 36px;
}
.RESULTS .marksix-results-table-container,.CHECKDRAWRESULT .marksix-results-table-container{
  width: calc(100vw - 12px);
  margin: 20px 6px 0;
}
.RESULTS  .marksix-results-print,.CHECKDRAWRESULT  .marksix-results-print{
  display: none;
}
.marksix-results-table-container .cell-sbName,
.marksix-results-table-container .cell-date{
  display: none;
}
.CHECKDRAWRESULT,
.RESULTS{
  .maraksx-results-table{
    border-radius: 0 0 4px 4px;
    overflow: hidden;
  }
}
.maraksx-results-table .table-row  .cell-id{
  max-width: 115px;
  min-width: 110px;
  padding: 8px 10px ;
  white-space: normal;
}

.maraksx-results-table .cell-id .result-date-m{
  display: block;
  color: #333;
  margin-top: 8px;
}
.maraksx-results-table .cell-ball-list>div .img-box{
  width: 35px;
  height: 34px;
  overflow: hidden;
  margin: 0 0 0 -5px;
}
.maraksx-results-table .cell-ball-list>div span {
  padding-left: 0px;
}
.maraksx-results-table .cell-ball-list span{
  margin: 0 5px 0 0px;
}
.maraksx-results-table .cell-ball-list>div{
  justify-content: space-between;
}
.maraksx-results-table .cell-ball-list{
  width:auto;
  min-width:initial;
  padding: 0 10px;
}
.result-lucky-number>div:first-child{
  max-width: 100%;
  margin-right: 0;
}
.FIXTURES .masonry-col>div {
  width: 100%;
  background-color: #FFFFFF;
}

.fixture-left {
  border-bottom: 1px solid #B4B4B4;
  border-right: 0;
  padding-bottom: 20px;
  margin: 8px 0px;
}

.fixture-right {
  margin: 8px 0px;
}

.FIXTURES .fixtures-header-m {
  display: none;
}

.STATISTICS .marksix-header-info, .RESULTS .marksix-header-info, .FIXTURES .marksix-header-info, .CHECKDRAWRESULT .marksix-header-info {
  display: none;
}

.LY .topMenu{
  display: none;
}
}
}