@import '../../css/common-mixin.scss';

.browserVersion {
  flex: 1;
}

header, section {
  @include font13();
}

.loading {
  min-height: 500px;
  width: 100%;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type="text"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #757575;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:focus {
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root {
  position: relative;
}

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
//   padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-left);
  padding-bottom: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  @include font11Body();
  font-family: 'Noto Sans TC', sans-serif;
  letter-spacing: 0;
  color: #333333;
  background-color: #E7E7E7;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  overflow-anchor: none;
  position: relative;
}

a {
  text-decoration: none;
}

.df {
  display: flex;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.cp {
  cursor: pointer;
}

.hide {
  overflow: hidden;
}

.dfc{
  display: flex;
  align-items: center;
}

.left-border, .right-border, .bottom-border, .top-border, .right-border-before, .left-border-before {
  position: relative;
}

.left-border::after {
  content: '';
  @include width1px();
  height: calc(100% - 8px);
  background-color: #e7e7e7;
  position: absolute;
  top: 4px;
  left: 0;
}

.left-border-before::before {
    content: '';
    @include width1px();
    height: calc(100% - 12px);
    background-color: #e7e7e7;
    position: absolute;
    top: 6px;
    left: 0;
  }

.right-border::after {
  content: '';
  @include width1px();
  height: calc(100% - 12px);
  background-color: #e7e7e7;
  position: absolute;
  top: 6px;
  right: 0;
}

.right-border-before::before {
    content: '';
    @include width1px();
    height: calc(100% - 12px);
    background-color: #e7e7e7;
    position: absolute;
    top: 6px;
    right: 0;
  }

.top-border::after, .top-block-item:not(:first-child)::after {
  content: '';
  width: 100%;
  @include height1px();
  background-color: #e7e7e7;
  position: absolute;
  left: 0px;
  top: 0;
}

.bottom-border::after {
  content: '';
  width: calc(100% - 24px);
  @include height1px();
  background-color: #e7e7e7;
  position: absolute;
  left: 12px;
  bottom: 0;
}

.verticalWord {
  writing-mode: vertical-lr;
  letter-spacing: 4px;
  white-space: nowrap;
  line-height: 24px;
  padding: 0px;
}

.old-version {
    display: flex;
    align-items: center;
    color: white !important;
    margin-left: auto;
    margin-right: 44px;
    column-gap: 6px;
    font-weight: 400;
    line-height: 12px;
    &.vsbHide{
      visibility: hidden;
    }
    img {
        width: 24px;
        // transform: translateY(1px);
    }
    &:hover{
      text-decoration: underline;
    }
}

.mobile-survey-lang-wrap {
    display: flex;
    // width: 130px;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 12px;
    .fMenuRowSurvey {
        margin-right: 24px;
    }
}

.fMenuRowLang, .fMenuRowSurvey {
//   width: 52px;
  vertical-align: bottom;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  margin-right: 24px;
}

.fMenuRowSurvey {
    margin-right: 40px;
}
.fMenuRowLang:hover > .topMenuLang, .fMenuRowSurvey:hover > .topMenuSurvey{
  // opacity: 0.5;
  text-decoration: underline;
}

.topMenuHome {
  line-height: 29px;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 400;
  @include H6();
}

.topMenuLang, .topMenuSurvey {
  color: #FFFFFF;
  text-decoration: none;
  float: right;
  margin-top: -1px;
  white-space: nowrap;
  @include H6();
}

.topMenuSurvey {
    font-size: 13px;
    font-weight: 500;
}

.topMenuIcon {
  content: url(../../info/Include/images/lang.svg);
  width: 24px;
  margin-left: -29px;
  margin-right: 2px;
  vertical-align: middle;
  // filter: invert(100%) sepia(0) saturate(0) hue-rotate(350deg) brightness(137%) contrast(101%);
}

.topMenuSurveyIcon {
    content: url(../../info/Include/images/Survey_desktop_small.svg);
    width: 24px;
    margin-left: -29px;
    margin-right: 2px;
    vertical-align: middle;
    // filter: invert(100%) sepia(0) saturate(0) hue-rotate(350deg) brightness(137%) contrast(101%);
  }

.fMenuRow {
  width: 20px;
  display: inline;
}



.fMenuTR {
  height: 32px;
  display: flex;
}

.GL .fMenuTR {
  background-color: #003C84;
}


.HR .fMenuTR, .HR .bsBgColor, .HRBlock .blockHeader {
  background-color: #122c68;
}

.FB .fMenuTR, .FB .bsBgColor, .FBBlock .blockHeader {
  background-color: #405a55;
}

.LY .fMenuTR, .LY .bsBgColor, .LYBlock .blockHeader {
  background-color: #980b0b;
}

.GL .bsBgColor {
  background-color: #F4F4F5;
}

// .fMenuRowHome {
//   flex-wrap: nowrap;
//   width: 36px;
//   padding: 2px 0px 0px 5px;
//   vertical-align: top;
//   width: 60px;
//   display: flex;
//   align-items: center;
// }

// .fMenu {
//   align-items: center;
//   display: flex;
//   margin-left: 10px;
//   flex-wrap: nowrap;
// }

// .fMenu div {
//   color: #FFFFFF;
//   margin-right: 30px;
//   cursor: pointer;
//   white-space: pre-wrap;
//   @include H6();
// }

// .ch .fMenu div {
//   max-width: 108px;
// }

// .fMenu div:hover, .topMenuHome:hover {
//   // color: #DBE6EF;
//   text-decoration: underline;
// }

.fMenuRowLine {
  background-image: url(../../info/Include/images/topmenu_line_v.gif);
  background-repeat: no-repeat;
  background-position: center;
  display: inline-flex;
  flex-wrap: nowrap;
  vertical-align: middle;
  @include width1px();
  height: 20px;
  vertical-align: top;
  padding-top: 8px;
}

.secMenu {
//   min-width: 435px;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  background: #ffffff;
  margin-right: 24px;
}

.secMenuItem {
  position: relative;
  width: 140px;
  height: 40px;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  @include H4();
  &.Int_ewin{
    &.isSpeedbet{
      width: 140px !important;
    }
    &:hover, &.active{
      .leftMenuIcon img{
        filter: brightness(0) saturate(100%) invert(14%) sepia(35%) saturate(7205%) hue-rotate(204deg) brightness(92%) contrast(101%);
      }
    }
  }
}

.secMenuItem::after {
  content: '';
  @include width1px();
  height: calc(100% - 8px);
  background-color: #b4b4b4;
  position: absolute;
  top: 4px;
  right: -1px;
}

.secMenuItem.active:not(.Int_ewin)::after {
  display: none;
}

.secMenuItem .leftMenuIcon {
  padding: 8px 8px 0px 12px;
}

.secMenuItem>div:nth-child(2) {
  padding-right: 12px;
  max-width: 80px;
}

#secMenuInt_Football, #secMenuInt_ewin{
  >div:nth-child(2){
    max-width: min-content;
  }
}

.ch .secMenuItem>div:nth-child(2) {
  padding-right: 12px;
  min-width: 60px;
  max-width: 60px;
}

.secMenuItem.Int_Racing.active, .secMenuItem.Int_Racing:hover  {
  background: #173E96;
  color: #ffffff;
}

.secMenuItem.Int_Football.active, .secMenuItem.Int_Football:hover {
  background: #00C06C;
  color: #ffffff;
}

.secMenuItem.Int_MarkSix.active, .secMenuItem.Int_MarkSix:hover {
  background: #D62D2D;
  color: #ffffff;
}

.secMenuItem.active,  .secMenuItem:hover{
  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
  }
}

.hueRotate{
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
}

.topInfoMenu {
  width: 100%;
  display: flex;
  background: #fff;
//   position: sticky;
//   top: 0;
//   z-index: 800;
}

// .HR .topInfoMenu {
//   border-bottom: 1px solid #173E96;

// }

// .FB .topInfoMenu {
//   border-bottom: 1px solid #00C06C;

// }

// .LY .topInfoMenu {
//   border-bottom: 1px solid #D62D2D;

// }

.hkjcpriority {
    background: white;
    position: relative;
    // padding-top: 3px;
    padding-right: 26px;
}

.hkjcpriority::after {
    content: ' ';
    display: block;
    position: absolute;
    background: #b4b4b4;
    @include width1px();
    top: 6px;
    bottom: 6px;
    right: 15px;
}

.hkjcpriority-logo {
    height: 31px;
    width: 132px;
    background-image: url('../../info/Include/images/main-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(0.8);
    padding-top: 28px;
    margin: auto 12px;
    font-size: 11px;
}


// .topNav {
//   width: auto;
//   flex-grow: 1;
//   flex-shrink: 1;
//   flex-basis: 60%;
//   max-height: 40px;
// }

.topNav ul {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  padding-left: 0;
  gap: 0 12px;
  height: 100%;
//   padding-right: 32px;
  > li{
    height: 100%;
    align-items: center;
    >div{
      height: 100%;
    }
  }
}
.en .topInfoMenu .topNav ul {
    padding-right: 32px;
}
.ch .topNav ul { // SQ0248-3194
    grid-gap: 0 18px;
}

.topNav .dropdown-menu.show {
  background-color: #FFFFFF;
  box-shadow: var(--menu-shadow);
  border: none;
  border-radius: 15px;
  width: 224px;
  padding: 4px 0;
}

.topNav .dropdown-item:before {
  content: "";
  position: absolute;
  top: -10px;
  right: 18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.topNav .dropdown-item:not(:first-child) {
  border-top: 1px #d2d2d2 solid;
  padding-bottom: 11px;
}
.topNav .dropdown-item:last-child {
  padding-bottom: 12px;
}

.tContainer {
  display: inline-flex;
  padding: 0px;
  background-color: white;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  line-height: 18px;
  font-weight: 400;
  height: 40px;
}

.tDropdown {
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  position: relative;
  white-space: nowrap;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  flex: 0 0 11%;
  @include font13();
  color: #000000;
  font-weight: 400;
}

.tContainer .ewin {
  flex: 0 0 8%;
  min-width: 80px;
}

.tContainer .ewin img {
  margin-right: 5px;
}

.important-notice {
  width: 100%;
  background-color: #fecf13;
  padding-left: 12px;
  display: flex;
  height: 32px;
  /* margin-left: 12px; */
}

.important-notice a {
  word-break: break-all;
}

.important-notice .icon{
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding-top: 1px;
}

.important-notice .icon img{
  width: 24px;
  height: 24px;
}

.important-notice .message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: 32px;
}

.important-notice .more {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 60px;
  height: 20px;
  line-height: 20px;
  border-radius: 16px;
  color: #fff;
  background-color: #003C84;
  cursor: pointer;
}

.important-notice .more.racing {
  background-color: #122c68;
}

.important-notice .more.football {
  background-color: #405a55;
}

.important-notice .more.marksix {
  background-color: #980b0b;
}

.important-notice .more.ewin {
  background-color: #003C84;
}


#divTopBanner {
  display: flex;
  height: 180px;
}

@media only screen and (min-width: 1680px) {
  #divTopBanner {
    height: 274px;
  }
}

.topBanner {
  margin: 0px 0px 8px 0px;
}

.info_top_promo {
  width: 78%;
//   padding: 0 8px;
  padding-left: 8px;

  .Banner-item {
      padding: 0 6px;

  }

  .center .Banner-item {
      display: inline-flex !important;
      justify-content: center;

      img {
          width: 100%;
          height: 100%;
      }
  }

  .slick-slide .Banner-item  {
      opacity: 1;
  }

  .slick-active:not(.slick-current) .Banner-item {
      opacity: 0.5;
  }

  .Banner-item.current {
      opacity: 1;
  }
}

.betslip-container {
  /* position: fixed;
  top: 30px; */
  position: sticky;
  top: 40px;
  width: 342px;
  left: 1107px;
  height: calc(100vh - 92px);
  height: calc(100dvh - 92px);
  z-index: 9000;
  &.isEWinPage {
    top: 44px;
  }
}

.rcMain, .fbMain, .marksixMain, .ewinMain {
  width: auto;
  min-height: calc(100vh - 280px);
  padding: 8px 8px 0px 8px;
}

.HR a {
  color: #6176a0;
}

.FB a {
  color: #61a06f;
}

@media (any-hover: hover) {
    .HR a:hover {
        color: #173e96;
    }

    .FB a:hover {
        color: #00c06c;
    }

    .LY a:hover {
        color: #d62d2d;
    }
}

.LY a {
  color: #923338;
}

.topMenu .dropdown-toggle:after {
  transform: rotateZ(45deg);
  display: inline-block;
  width: .3em;
  height: .3em;
  margin: -.3em 0 0 .4em;
  vertical-align: middle;
  content: "";
  border: .3em solid;
  border-width: 0 .05em .05em 0;
  font-size: 21px;
  margin-left: 5px;
  display: none;
}

.ch .rcMain .dropdown-toggle:after {
  margin-left: 10px;
}

.topMenu button {
  color: #333333;
  border-width: 0px;
  vertical-align: middle;
  min-height: 40px;
  padding: 8px;
  @include font13();
  width: 100%;
  /* white-space: nowrap; */
  white-space: initial;
  border-radius: 0;
  line-height: 15px;
  @include H4();
}

.topMenu li:first-child button {
    border-radius: 4px 0 0 4px;
    min-width: 40px;
}

.topMenu li:last-child button {
    border-radius: 0 4px 4px 0;
}

.topMenu .btn-group {
  width: 100%;
}

.topMenu .btn-group button, .topMenu .dropdown.btn-group button {
  background-color: #F4F4F4;
  color: #333333;
  position: relative;
  @include font13();
}

.hlMenuText,
.topMenu .btn-group.hlMenuText button,
.topMenu .dropdown-item.hlMenuText,
.modal.more .content .racing .accordion-body>div.hlMenuText,
.modal.more .content .football .accordion-body>div.hlMenuText,
.modal.more .content .marksix .accordion-body>div.hlMenuText  {
  color: #ff0000;
}
.FB .top-block-item.hlMenuText{
  color: #569171;
}
.LY .top-block-item.hlMenuText{
  color: #CB7B7B;
}

.HR .topMenu button:hover, .HR .topMenu button.active, .HR .topMenu .btn-group.active button{
  background-color: #173e96;
  color: #FFFFFF !important;
  z-index: 1;
}

.FB .topMenu button:hover, .FB .topMenu button.active, .FB .topMenu .btn-group.active button{
  background-color: #00c06c;
  color: #FFFFFF !important;
  z-index: 1;
}

.LY .topMenu button:hover, .LY .topMenu button.active, .LY .topMenu .btn-group.active button {
  background-color: #d62d2d;
  color: #FFFFFF !important;
  @include font13();
}

.topMenu {
  border-radius: 4px;
  /* width: 1064px; */
  background-color: #F4F4F4;
  box-shadow: var(--box-shadow);
}

.dropdown-menu.show {
  background-color: #FFFFFF;
  -webkit-box-shadow: var(--menu-shadow);
  box-shadow: var(--menu-shadow);
  border: none;
  padding: 4px 0;
}

.topMenu .dropdown-menu {
  border-radius: 10px;
  width: 224px;
}

.topMenu .dropdown-item:not(:last-child) {
  /* border-bottom: 1px #d2d2d2 solid; */
  // padding-bottom: 6px;
}

.topMenu .dropdown-item:not(:first-child) {
  padding-bottom: 11px;
}
.topMenu .dropdown-item:last-child {
  padding-bottom: 12px;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: unset;
}

.topMenu ul {
  position: relative;
  margin: 0px 0px 8px 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
}

.topMenu ul li {
  display: inline-block;
  position: relative;
  /* width: 100%; */
  text-align: center;
  flex-grow: 1;
  display: flex;
}

.topMenu ul li:not(:first-child)::after {
  content: '';
  @include width1px();
  height: 70%;
  position: absolute;
  background: #e7e7e7;
  top: 15%;
  left: 0;
}

.topMenu ul li button:focus {
  outline: none;
  box-shadow: none;
}

.logoutDropBtn .dropdown-toggle:after{
  display: none;
}
.GL .logoutDropBtn .menu-txt{
  color: #333333;
}
.logoutDropBtn .dropdown-item:before{
  left: 10px;
}
.logoutDropBtn .dropdown-menu.show{
  inset: 8px auto auto auto !important;
}
.loginDropBtn .dropdown-item:before{
  left: 30px;
}
.loginTandC{
  z-index: 9999;
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  background-color: #E7E7E7;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8999;
}
.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.loginTandC .loginTandCHeader{
  color: #333333;
  padding: 18px 0 0px 13px;
  @include H4();
}
.FB .loginTandCHeader{
  color: #405a55;
}
.HR .loginTandCHeader{
  color: #003C84;
}
.LY .loginTandCHeader{
  color: #980b0b;
}
.loginTandC .loginTandCText{
  padding: 10px 13px 0px 11px;
}
.loginTandC .loginTandCText .tandCTb{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 286px);
  padding: 10px 10px 0px 10px;
  border-radius: 6px 6px 0 0;
  background-color: #ffffff;
  @include H5();
  line-height: 18px;
  table tbody tr{
    &>td:first-child{
      vertical-align: top;
    }
    &>td:last-child{
      padding-bottom: 8px;
    }
  }
}
.speedbetTandC .loginTandC .loginTandCText .tandCTb{
  height: calc(100vh - 320px);
}
.loginTandC .loginTandCText .tandCTextRow{
  display: grid;
  grid-template-columns: 25px auto;
}
.loginTandC .loginTandCText .tandCTextRowL{
  font-weight: 500;
}
.loginTandC .loginTandCText .tandCTextRowR{
  padding: 0px 0px 20px 0px;
  word-break: break-word;
  white-space: pre-wrap;
}

.loginTandC .loginTandCProceed{
  height: 68px;
  background-color: #fefefe;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 15px;
}
.loginTandC .loginTandCProceed .tandCBtn{
  width: 100px;
  height: 32px;
  background: #fecf13;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #122c68;
  cursor: pointer;
  user-select: none;
  @include H5();
  &.dim:hover{
    background: #fecf13;
    cursor: default;
  }
}
.loginTandC .loginTandCProceed .tandCBtn:hover{
  background-color: #E6BB11;
}
.loginTOArea{
  position: absolute;
  left: 0;
  top: -6px;
  width: 100%;
  background-color: #F6F6F6;
  padding-top: 20px;
  padding-bottom: 20px;
}
.loginTOArea .loginTO{
  background-color: #ffffff;
  border-radius:  6px;
  overflow: hidden;
  width: 94%;
  margin-left: 3%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginTOArea .loginTOHeader{
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.loginTOArea .loginTOText{
  padding: 0px 4px 0 4px;
  line-height: 18px;
}
.loginTOArea .otRedFont{
  @include font13();
  font-weight: bold;
  color: red;
}
.loginTOArea .otBlueFont{
  color: #173e96;
  cursor: pointer;
}
.loginTOArea .otBlueFont:hover{
  color: blue;
}
.loginTOArea .margB20{
  margin-bottom: 20px;
}
.loginTOArea .margB10{
  margin-bottom: 10px;
}

.rcMiddle, .fbMiddle {
  display: flex;
  min-height: 500px;
  /* width: 1064px; */
}
.leftMenuMain{
  margin-right: 8px;
  .hlMenuText {
    color: #ff0000;
  }
}
.leftMenuMain >ul {
  background-color: white;
  /* flex: 0 0 20%; */
  /* min-width: 224px; */
  width: 100%;
  border-radius: 0 0 4px 4px;
  .LY &{
    border-radius: 4px;
  }
  box-shadow: var(--box-shadow);
}

.leftMenuMain .active {
  background-color: #173E96;
  color: white !important;
}
.leftMenuMain .leftMenu-top{

  background-color: #fff;
  border-radius: 4px 4px 0 0;
}
.HR .leftMenu-top {
  padding: 10px;
  color: #6176A0;
  font-weight: 600;
  padding-bottom: 5px;
}

.FB .leftMenu-top {
  padding: 10px;
  color: #569171;
  font-weight: 600;
  padding-bottom: 5px;
}

.LY .leftMenu-top {
  padding: 10px;
  color: #980B0B;
  font-weight: 600;
//   padding-bottom: 5px;
  border-top: 1px solid #dee2e6;

}

.leftMenuMain ul {
  padding: 0px;
  list-style-type: none;
  margin-bottom: 0px;
}

.leftMenuMain li {
  border-bottom: 1px solid #dee2e6;

  padding: 11px 10px 10px 10px;
  color: #333333;
  user-select: none;
  word-break: break-word;
  @include H4();
}
@media (any-hover: hover){
  .leftMenuMain li:hover, .leftMenuMain .son-menu:hover, .leftMenuItemPoint:hover {
  //   color: #173E96;
      .HR & {
          // color: #173E96;
          background-color: #173E96;
          color: #FFFFFF !important;
      }
      .FB & {
          // color: #00C06C;
          background-color: #00c06c;
          color: #FFFFFF !important;
      }
      .LY & {
          // color: #D62D2D;
          background-color: #D62D2D;
          color: #FFFFFF !important;
      }
      .GL & {
          // color: #003c84;
          background-color: #003c84;
          color: #FFFFFF !important;
      }
  }
}

.leftMenuMain li.active:hover, .active .leftMenuItemPoint:hover {
  color: #FFFFFF;
}

.leftMenuMain div.son-menu {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
//   padding-left: 10px;
//   padding-bottom: 5px;
//   padding-top: 5px;
  padding: 5px 10px;
  /* line-height: 25px; */
  border-bottom: 1px solid #dee2e6;

  line-height: 25px;
  @include font13();
}

.leftMenuMain ul>div {
  padding-left: 0px;
}

.leftMenuMain ol li {
  border: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.leftMenuMain ol {
  list-style-type: disc;
  padding-bottom: 0;
  padding-left: 28px;
  border: none;
}

.leftMenuMain ol li span {
  margin-left: -7px;
}

.cursor-pointer {
  cursor: pointer;
  /* border-bottom: 1px solid #dee2e6; */
  /* padding-left: 15px;
  padding-bottom: 5px; */
}

.leftMenuItemPoint::before {
  content: ".";
  font-size: 20px;
  vertical-align: 3px;
  margin-right: 5px;
  line-height: 1px;
}

.leftMenuIcon {
  padding-left: 4px;
  transform: translate(0, -3px);
  display: inline-block;
}

.tContainer .leftMenuIcon {
    position: absolute;
}

// .leftMenuMain .leftMenuIcon {
//     transform: translateY(0);
// }

.inline-flex {
    display: inline-flex;
}

.flex-center {
  display: inline-flex;
  align-items: center;
}

.footer {
  width: 100%;
  height: auto;
  margin-top: 15px;
  padding: 21px 25px 0px 25px;
  background-color: white;
  position: relative;
}

.footer-banner-media {
  height: 64px;
  display: flex;
  width: 195px;
  margin-right: 24px;
  background: #ECEDEF;
  border-radius: 4px;
  justify-content: flex-start;
}

.footer-banner-media-area {
  width: 72px;
  height: 55px;
  margin: 5px 0px 0px -3px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.footer-banner-media-icon {
  width: 40px;
  height: 40px;
  background-image: url(../../info/Include/images/media_communication_system.svg);
}

.footer-banner-media-content {
  height: 57px;
  width: 90px;
  @include font13();
  color: #6A6D73;
  @include lineHeight16();
  font-weight: 700;
  display: flex;
  align-content: center;
  align-items: center;
}

:where(.isDeviceVersion) {
.footer-top
{
    display: grid;
    gap: 12px 0;
    grid-template-columns: auto auto 1fr;
    @media (max-width: 768px) and (pointer: coarse) {
            grid-template-columns: auto 1fr;
            .footer-banner-rgp-content {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
            }
        }
    .footer-banner-rgp-imag {
        margin-left: auto;
    }
}
}

.footer-play-responsibly {
  color: #003C84;
  margin-bottom: 13px;
  @include Body-Bold();
}

.footer-play-responsibly-content {
  color: #333333;
  padding-bottom: 15px;
  @include Body();
  p {
    margin: 0;
  }
}

.footer-play-responsibly-imag {
  width: 94px;
  height: 49px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  @include font11();
  text-align: center;
  color: #333333;
  cursor: pointer;
}

.footer-play-responsibly-imag:hover {
  border: none;
  outline: none;
  text-decoration: none;
  color: inherit;
}


.footer-buttom {
  flex: 0 0 100%;
  padding: 15px 0px 15px 0px;
  display: flex;
  border-top: 1px solid #A3A3A3;

}

.rgpLinks a:not(:last-child):after {
  display: inline-block;
  /* vertical-align: middle; */
  content: "|";
  margin-left: 7px;
  margin-right: 7px;
}

.rgpLinks {
  color: #6176A0;
  flex: 0 0 55%;
}

.rgpLinks a {
  color: #003c84;
  @include H6();
}

.copyRight, .copyRight a {
  color: #333333;
  @include H6();
}

.ewinCopyRight, .ewinCopyRight a {
  color: #333333;
  @include H6();
}

.rgpLinks a:hover, .copyRight a:hover {
  color: #0d6efd;
}

.copyRight {
  flex: 0 0 45%;
  justify-content: flex-end;
  display: flex;
}
.ewinCopyRight {
  margin-left: auto;
  text-align: right;
}

.topMenu .dropdown-item:before {
  content: "";
  position: absolute;
  /* right: 11px; */
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.info_top_frame {
  border: 0;
  margin: 0;
  width: 100%;
  height: 250px;
}

#race-block-pool .content {
  display: flex;
  margin: 2px 0px;
}

#race-block-pool .content div:first-child {
  white-space: nowrap;
  width: 70%;
}

#race-block-pool .content div:last-child {
  white-space: nowrap;
  width: 40%;
}

#race-block-pool .jpEstTable {
  margin-top: 12px;
}

.tab-content hr {
  margin: 11px 6px 6px 0px;
}

.tab-content.jpEstTable hr {
  margin: 4px 6px 7px 0p;
}

.topNav .dropdown-toggle:after {
  display: none;
}

.important-icon {
  background-image: url(../../info/Include/images/important_icon.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
}

// .FB #divTopBanner #info_top_promo {
//   background-image: url(../../info/Include/images/dummy-Banner.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   flex: 1;
// }

// .HR #divTopBanner #info_top_promo {
//   background-image: url(../../info/Include/images/dummy-Banner.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   flex: 1;
// }

// .GL #divTopBanner #info_top_promo {
//   background-image: url(../../info/Include/images/dummy-Banner.png);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   flex: 1;
// }

.LY #divTopBanner #info_top_promo {
  background-image: url(../../info/Include/images/dummy-Banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 1;
}

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
  opacity: 1;
//   font-size: 24px;
//   line-height: 1.2;
//   color: white;
//   position: absolute;
//   top: -3px;
//   right: -4px;
}

.slick-next:before, .slick-prev:before {
  opacity: 1;
  font-size: 24px;
  color: white;
  position: absolute;
  top: -4px;
  right: -4px;
}

.slick-arrow.slick-prev {
  background: #333333;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  margin-left: 8px;
  box-shadow: 0px 1px 3px 3px rgb(147 150 153 / 13%);
}

.slick-arrow.slick-next {
  background: #333333;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  margin-left: 8px;
  box-shadow: 0px 1px 3px 3px rgb(147 150 153 / 13%);
}

// .slick-next:before, .slick-prev:before {
//   top: -1px;
// }


.fMenuTR-mobile, .bm-cross {
  display: none;
}

.bm-item.accordion {
  flex-wrap: wrap;
}

.accordion-button::after {
  transform: rotate(270deg);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}

.dd-arrow {
  border: solid #122C68;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin: 2px 2px 0px 0px;
  height: 0;
  width: 0;
}

.dd-arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.dd-arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.dd-arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dd-arrow-down {
  float: right;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.meeting-button .slider-container {
  /* width: 460px; */
//   margin-right: 35px;
//   margin-left: 14px;
    // border-left: 1px solid #979797;
}

.meeting-info .meeting-info-menu, .bottom-menu {
  display: none;
}


.slick-list {
  margin-left: 9px;
//   overflow: unset; // 為了移動端顯示box-shadow
}

:focus-visible {
  outline: none;
}


.important-notice-mobile {
  display: none;
}


.lineH13{
  line-height: 13px;
}
.lineH18{
  line-height: 18px;
}
.marginB1{
  margin-bottom: 1px;
}
.marginB2{
  margin-bottom: 2px;
}

// .login-account .df {
//   display: grid;
//   grid-template-columns: 80% 20%;
// }

// .signIn:hover {
//   opacity: 0.8;
// }


.icon-racingnote{
  background-image: url(../../info/Include/images/racing_note.svg);
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
}
.icon-racingnoteUpdate{
  background-image: url(../../info/Include/images/notes_view_black.svg);
  width: 18px;
  height: 21px;
  background-size: 18px 21px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
}
.icon-setting{
  background-image: url(../../info/Include/images/settings_icon.svg);
  width: 24px;
  height: 24px;
}

.slider-container-venue {
//   width: 100%;
  height: 44px;
  margin: 0px;
//   border-right: 1px solid #979797;
  display: flex;
  align-items: center;
}

.slider-container-venue .slick-list {
  width: auto !important;
}

.slider-container-venue .slick-initialized .slick-slide {
//   width: 40px !important;
}


.hide-d {
  display: none;
}

.show-d {
  display: block;
}

.icon-table {
  display: inline-block;
  background: url('../../info/Include/images/icon_table.svg') no-repeat;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  margin: 0 12px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
  background-position-y: 3px;
}

.icon-table-white {
  display: inline-block;
  background: url('../../info/Include/images/icon_table.svg') no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  margin: 0 12px;
  transform: translateY(3px);
}
.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header{
  font-size: 16px;
}
.react-datepicker__month-container{
  float: none;
}
.datePickerConfirm{
  height: 60px;
  border-top: 1px #e4e4e4 solid;

  display: flex;
  align-items: center;
  justify-content: center;
}
.datePickerConfirm .datePickerConfirmBtn {
  color: #ffffff;
  width: 110px;
  height: 32px;
  border: 0px;
  border-radius: 15px;
  @include font13();
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.datePickerConfirm .datePickerConfirmBtn:hover{
  opacity: 0.8;
}
.FB div.date-picker-container.date_range_picker .datePickerConfirmBtn {
  background-color: #569171;
}

.FB div.date-picker-container.date_range_picker .dateRangePickerValueContainer {
  color: #405a55;
}

.LY div.date-picker-container.date_range_picker .datePickerConfirmBtn {
  background-color: #cb7b7b;
}

.LY div.date-picker-container.date_range_picker .dateRangePickerValueContainer {
  color: #980b0b;
}

.HR div.date-picker-container.date_range_picker .datePickerConfirmBtn {
  background-color: #6176a0;
}

.HR div.date-picker-container.date_range_picker .datePickerConfirmBtn {
  background-color: #122c68;
}

.FB div.date-picker-container.date_range_picker .react-datepicker__day--selected,
.FB div.date-picker-container.date_range_picker .react-datepicker__day--in-range,
.FB div.date-picker-container.date_range_picker .react-datepicker__day--range-start::before,
.FB div.date-picker-container.date_range_picker .react-datepicker__day--range-end::before,
.FB div.date-picker-container.date_range_picker .react-datepicker__month-text--selected,
.FB div.date-picker-container.date_range_picker .react-datepicker__month-text--in-range,
.FB div.date-picker-container.date_range_picker .react-datepicker__quarter-text--selected,
.FB div.date-picker-container.date_range_picker .react-datepicker__quarter-text--in-range,
.FB div.date-picker-container.date_range_picker .react-datepicker__year-text--selected,
.FB div.date-picker-container.date_range_picker .react-datepicker__year-text--in-range {
    background-color: #deeae4;
}

.FB div.date-picker-container.date_range_picker .react-datepicker__day .selectedDay {
    background-color: #00C06C;
}

.LY div.date-picker-container.date_range_picker .react-datepicker__day--selected,
.LY div.date-picker-container.date_range_picker .react-datepicker__day--in-range,
.LY div.date-picker-container.date_range_picker .react-datepicker__day--range-start::before,
.LY div.date-picker-container.date_range_picker .react-datepicker__day--range-end::before,
.LY div.date-picker-container.date_range_picker .react-datepicker__month-text--selected,
.LY div.date-picker-container.date_range_picker .react-datepicker__month-text--in-range,
.LY div.date-picker-container.date_range_picker .react-datepicker__quarter-text--selected,
.LY div.date-picker-container.date_range_picker .react-datepicker__quarter-text--in-range,
.LY div.date-picker-container.date_range_picker .react-datepicker__year-text--selected,
.LY div.date-picker-container.date_range_picker .react-datepicker__year-text--in-range {
  background-color: #f4e6e6;
}

.LY div.date-picker-container.date_range_picker .react-datepicker__day .selectedDay {
    background-color: #d62d2d
}

.HR div.date-picker-container.date_range_picker .react-datepicker__day--selected,
.HR div.date-picker-container.date_range_picker .react-datepicker__day--in-range,
.HR div.date-picker-container.date_range_picker .react-datepicker__day--range-start::before,
.HR div.date-picker-container.date_range_picker .react-datepicker__day--range-end::before,
.HR div.date-picker-container.date_range_picker .react-datepicker__month-text--selected,
.HR div.date-picker-container.date_range_picker .react-datepicker__month-text--in-range,
.HR div.date-picker-container.date_range_picker .react-datepicker__quarter-text--selected,
.HR div.date-picker-container.date_range_picker .react-datepicker__quarter-text--in-range,
.HR div.date-picker-container.date_range_picker .react-datepicker__year-text--selected,
.HR div.date-picker-container.date_range_picker .react-datepicker__year-text--in-range {
    background-color: #d1d6e2;
}

.HR div.date-picker-container.date_range_picker .react-datepicker__day .selectedDay {
    background-color: #173e96;
}


.FB div.date-picker-container.date_range_picker .react-datepicker__day--range-start,
.FB div.date-picker-container.date_range_picker .react-datepicker__day--range-end,
.LY div.date-picker-container.date_range_picker .react-datepicker__day--range-start,
.LY div.date-picker-container.date_range_picker .react-datepicker__day--range-end,
.HR div.date-picker-container.date_range_picker .react-datepicker__day--range-start,
.HR div.date-picker-container.date_range_picker .react-datepicker__day--range-end {
    background-color: unset;
}

.help-icon {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-left: 10px;
  background-image: url(../../info/Include/images/ic_help_white.svg);
  background-size: cover;
  cursor: pointer;
}
.GL .help-icon {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-left: 10px;
  background-image: url(../../info/Include/images/ic_help_black.svg);
  background-size: cover;
  cursor: pointer;
}

.raceNotes-icon.add {
  height: 20px;
  width: 24px;
  background-image: url(../../info/Include/images/notes_new_black.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 4px;
  cursor: pointer;
}

.raceNotes-icon.update {
  height: 20px;
  width: 24px;
  background-image: url(../../info/Include/images/notes_view_black.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 4px;
  cursor: pointer;
}

.GL .raceNotes-icon.add {
  height: 20px;
  width: 24px;
  background-image: url(../../info/Include/images/notes_new.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 4px;
  cursor: pointer;
}

.GL .raceNotes-icon.update {
  height: 20px;
  width: 24px;
  background-image: url(../../info/Include/images/notes_view.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 4px;
  cursor: pointer;
}

.eyeOn-icon {
  background-image: url(../../info/Include/images/eye_on.svg);
  margin-left: 7px;
  cursor: pointer;
  width: 18px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.eyeClose-icon {
  background-image: url(../../info/Include/images/eye_close.svg);
  margin-left: 7px;
  cursor: pointer;
  width: 18px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 1px;
}

.refresh-icon {
  background-image: url(../../info/Include/images/ic_refresh_white.svg);
  margin-left: 7px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &.rotate {
    animation: rotate 1s linear 1;
  }
}

.GL,.pcard{
  .eyeOn-icon {
    background-image: url(../../info/Include/images/eye_on_black.svg);
  }
  .eyeClose-icon {
    background-image: url(../../info/Include/images/eye_close_black.svg);
  }
  .refresh-icon {
    background-image: url(../../info/Include/images/ic_refresh_black.svg);
  }
  .icon-setting{
    background-image: url(../../info/Include/images/settings_icon_black.svg);
  }
}
.StandardPage #divBetSlipNormal  .logonState{
  .login-account{
    padding-left: 16px;
  }
  .link-account{
    margin-left: 4px;
  }
}


.external-icon {
  height: 15px;
  width: 15px;
  background-image: url(../../info/Include/images/icon_external.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bgMusic{
  visibility: hidden;
  display: none;
}

.dropdown-toggle::after {
  display: none;
}
.date-input {
  height: 32px;
  width: 305px;
  padding: 0 38px 0 70px;
  background-color: transparent;
  border: 1px solid #666;
  @include font13();
  text-align: right;
}

 .date-picker-container{
  position: relative;
  margin-right: 20px;
  width: 100%;
}
 .date-picker-container .date-picker-from{
  position: absolute;
  left: 13px;
  top: 0;
  height: 32px;
  display: flex;
  align-items: center;
  z-index: 10;
}
 .date-picker-container .date-dd-arrow {
  position: absolute;
  right: 0px;
  top: 8%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.dropdown.btn-group{
  .dropdown-menu-Int_RetailAndTelebet{
    border-radius: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    // inset: -2px 11px auto auto !important;
  }
}
.retailAndTelebet{
  display: flex;
  width: 224px;
  height: 168px;
  position: relative;
  background: #fff;
  border-radius: 15px;
  padding: 4px 0;
  box-shadow: 0 10px 18px 0px #0000005c;;
  &.activeDetail{
    border-radius: 15px 0 0 15px;
    .retailAndTelebet-l{
      padding-right: 2px;
    }
  }
  .retailAndTelebet-l{
    width: 224px;
    flex: 1;
    position: relative;
    z-index: 10;
    > .RATdropdown-item{
      position: relative;
      min-height: 36px;
      padding: 10px 12px 12px;
      @include H5();
      // @include lineHeight14();
      cursor: pointer;
      &:not(:first-child){
        border-top: 1px solid #d2d2d2;
      }
      &:first-child::before{
        content: '';
        position: absolute;
        right: 18px;
        top: -14px;
        width: 20px;
        height: 10px;
        border:solid;
        border-width:  0 10px 10px 10px;
        border-color: transparent transparent #fff transparent  ;
      }
      &.active::after{
        content: '';
        position: absolute;
        right: -10px;
        top: 10px;
        width: 10px;
        height: 20px;
        border:solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #FFF ;
      }
    }
  }
  .retailAndTelebet-r{
    position: absolute;
    left: 223px;
    top: 0;
    display: block;
    width: 336px;
    height: 168px;
    &.active{
      display: block;
    }
    .topNavMouseOver{
      top: 0px;
      left: 170px;
      display: none;
      z-index: 1000;
      width: 336px;
      height: 100%;
      border-radius:0 15px 15px 0;
      background-color: #ffffff;
      color: #333;
      box-shadow: 5px 10px 9px  rgb(0 0 0 / 36%);
      &.active{
        display: flex;
        }
      > :first-child{
        width: 110px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      > .RATContent{
        width: 226px;
        @include H5();
        @include lineHeight18();
        >:first-child{
          @include H4();
          margin-bottom: 4px;
        }
      }
    }
  }
}

.en .topNavMouseOver {
  left: 200px;
}

.topNavMouseOver>div:last-child {
  padding: 8px;
  white-space: normal;
}

.mobile-block{
  display: none !important;
}

.icon-accepted {
  background-image: url(../../info/Include/images/icon_success.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
}
.icon-rejected {
  background-image: url(../../info/Include/images/eft_rejected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
}
.icon-unknown {
  background-image: url(../../info/Include/images/icon_timeout.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
}
.icon-pending {
  background-image: url(../../info/Include/images/icon_pending.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}
.icon-s{
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.yellowCircleHL {
  position: absolute;
  top: 10px;
  left: 70%;
  background-color: #ffd505;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  &.moreThan99{
    font-size: 7px;
  }
}

.cashoutInProcess{
  opacity: 0.3;
  pointer-events: none;
}

.pageLoad {
  margin-top: 12px;
}

.noInfoTip{
  @include H2();
  margin-top: 24px;
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
}
.cashoutMenuNo {
  width: 15px;
  height: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #FECF13;
  border-radius: 50%;
  font-size: 9px;
  margin-left: 4px;
  color: #333;
  &.moreThan99{
    font-size: 7px;
  }
  li:hover &{
    color: #fff;
  }
}

.iconSize8{
  @include setIconSize(8);
}
.iconSize20{
  @include setIconSize(20);
}
.iconSize24{
  @include setIconSize(24);
}
.iconSize32{
  @include setIconSize(32);
}
.iconSize24And34{
  img{
    width: 24px;
    height: 34px;
  }
}
.iconSize40{
  @include setIconSize(40);
}

.topMenu,.leftMenuMain {
  #cashout:hover, #cashout.active, #Int_Early_Settlement:hover, #Int_Early_Settlement.active{
    img{
      filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
    }
  }
  .leftMenuIcon {
    position: relative;
    // img{
    //   height: 24px;
    //   width: 24px;
    // }

    .cashoutMenuNo{
      position: absolute;
      right: -3px;
      top: -2px;
    }
  }
}
// finalist
// wincntt
// wincnty

.leftMenuMain {
    #chp:hover, #chp.active, #finalist:hover, #finalist.active, #wincntt:hover, #wincntt.active, #wincnty:hover, #wincnty.active {
      img{
        filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
      }
    }
}

.popupTitle {
  height: 60px !important;
  color: #FFFFFF;
  font-size: 18px !important;
  line-height: 60px !important;
  font-weight: 500 !important;
  position: relative;
  .closeButton{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 20px;
    background-image: url(../../info/Include/images/icon_close.svg);
    cursor: pointer;
  }
}

.navigation-menu {
    a[value="chp"].active, a[value="finalist"].active, a[value="wincntt"].active, a[value="wincnty"].active {
        img{
          filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
        }
      }
}


.popup-content.MiuiBrowser-content {
    margin: auto;
    width: 90%;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    font-size: 13px;
    .MiuiBrowser-msg {
        margin-bottom: 12px;
    }
    .MiuiBrowser-img {
        .MiuiBrowser-img-explain {
            margin-bottom: 24px;
        }
        img {
            width: 100%;
        }
    }
    .close-btn-box {
        margin-top: 20px;
    }
    .MiuiBrowser-container .close {
        background-color: #6176a0;
        width: 84px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #fff;
        margin-left: auto;
    }
}

#moreMenuracing {
  .collapse:not(.show) {
    visibility: hidden; 
    height: 0;
    display: block;
  }

  .collapsing {
    transition: height .35s ease;
  }
}
