@media only screen and (min-width: 1441px) {
    .fbOddsTable section > .header,
    .fbOddsTable section .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [clock] 128px [odds] 28% [h2h] 48px [end];
    }
    .fbOddsTable section.INPLAY > .header,
    .fbOddsTable section.INPLAY .match-row-container > .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr 48px [clock] 128px [odds] 30% [h2h] 48px [end];
        .live-info{
            text-align: center;
        }
    }
    .fbOddsTable section.HIL>.match-row-container>.match-row,
    .fbOddsTable section.FHL>.match-row-container>.match-row,
    .fbOddsTable section.FCH>.match-row-container>.match-row,
    .fbOddsTable section.CHL>.match-row-container>.match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [clock] 128px [odds] calc(20% + 100px) [h2h]  48px [end];
        .oddsLine{
            padding-left: 0;
        }
    }
    .fbOddsTable section.HIL>.header,
    .fbOddsTable section.FHL>.header,
    .fbOddsTable section.FCH>.header,
    .fbOddsTable section.CHL>.header {
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [clock] 128px [line] 100px [odds] 20% [h2h]  48px [end];
    }

    .fbOddsTable section.FGS>.header,
    .fbOddsTable section.FGS .match-row,
    .fbOddsTable section.MSP>.header,
    .fbOddsTable section.MSP .match-row,
    .fbOddsTable section.SGA>.header,
    .fbOddsTable section.SGA .match-row {
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px [teamName] 405px [odds] 1fr [h2h] 48px [end];
    }

    .fbOddsTable section.TTG > .header,
    .fbOddsTable section.TTG .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [clock] 104px [odds] 416px [h2h] 48px [end];
    }
    .fbOddsTable section.OOE > .header,
    .fbOddsTable section.OOE > .match-row-container > .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [odds] 223px [h2h] 48px [end];
    }
    .fbOddsTable section.HFT > .header,
    .fbOddsTable section.HFT .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr 48% [h2h] 48px [end];
        .odds{
            padding: 12px;
        }
    }



    .fbOddsTable section.CRS > .header,
    .fbOddsTable section.FCS > .header,
    .fbOddsTable section.CRS .match-row,
    .fbOddsTable section.FCS .match-row{
        grid-template-columns: [first] 140px [frontEndId] 94px [tourn] 72px 1fr [clock] 104px [odds] 30% [h2h] 48px [end];
    }

    .fb-results-match-results {
        .table-row{
            & > .table-cell{
                padding: 8px 24px;
            }
            &>.date-cell{
                padding-left: 12px;
                width: 104px;
            }
            .had-name{
                padding: 8px 12px;
            }
            .matchNo-cell{
                width: 96px;
            }
            .flag-cell{
                width: 72px;
            }
            .full-time-cell,.half-time-cell{
                width: 130px;
            }

            .void-half-time span{
                width: 260px;
            }
            .detail-cell, .last-odds-cell{
                width: 82px;
            }
        }
    }

    .fbOddsTable section .match-row .team>div:nth-child(2) {
        margin: 0px 0px 0px 12px;
    }

    .fbOddsTable section .match-row .inplay-clock {
        padding: 0px 24px;
    }
}



@media only screen and (max-width: 1650px){
    .fbOddsTable section.TTG > .header, .fbOddsTable section.TTG .match-row{
        grid-template-columns: [first] 90px [frontEndId] 62px [tourn] 40px 1fr [clock] 70px [odds] 360px [h2h] 48px [end];
        .fb-id, .tourn{
            padding-right: 8px;
            padding-left: 8px;
        }
        .date{
            padding-right: 8px;
        }
    }

    .fbOddsTable section.HFT>.header,
    .fbOddsTable section.HFT .match-row {
        grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr 400px [h2h] 48px [end];
        .date, .fb-id, .tourn{
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .fbOddsTable section.HFT .match-row .odds {
        justify-items: center;
        padding: 14px 12px;
        gap: 8px 0;
    }

    .fbOddsTable section .match-row{
        // .date, .fb-id {
        //     padding-left: 12px;
        //     padding-right: 12px;
        // }

    }

    .fbOddsTable section.TTG .match-row{
        div.inplay-clock {
            gap: 8px 0;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: center;
            grid-template-columns: 100%;
            padding: 8px 12px;
            .corner-result{
                grid-column: 1/3;
                justify-items: center;
                align-items: center;
                height: auto;
            }
        }
        .exepcted-stop-time {
            margin-right: 6px;
            margin-left: 0;
            width: 22px;
            text-align: left;
        }
    }

    .fbOddsTable section.TTG .match-row .odds {
        // gap: 8px;
    }

    // .fbOddsTable section.HIL>.match-row-container>.match-row, .fbOddsTable section.FHL>.match-row-container>.match-row, .fbOddsTable section.CHL>.match-row-container>.match-row {
    //     grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [odds] minmax(236px, 28%) [h2h] 48px [end];
    // }


    // .fbOddsTable section.HIL>.header,
    // .fbOddsTable section.FHL>.header,
    // .fbOddsTable section.CHL>.header {
    //     grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [line] 9% [odds] 19% [h2h]  48px [end];
    // }

    // .fbOddsTable section > .header, .fbOddsTable section .match-row{
    //     grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [odds] 28% [h2h] 48px [end];
    // }

    .fbOddsTable section.INPLAY>.header,  .fbOddsTable section.INPLAY .match-row-container>.match-row {
        grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr 40px [clock] 104px [odds] 26% [h2h] 48px [end];
    }

    // .fbOddsTable section.CRS>.header,
    // .fbOddsTable section.FCS>.header,
    // .fbOddsTable section.CRS .match-row,
    // .fbOddsTable section.FCS .match-row {
    //     grid-template-columns: [first] 128px [frontEndId] 68px [tourn] 40px 1fr [clock] 128px [odds] 30% [h2h] 48px [end];
    // }

    .fbOddsTable {
        section{
            &.HIL, &.FHL, &.CHL, &.FCH {
                .match-row .oddsLine {
                    gap: 12px 12px;
                }
            }
        }
    }

    .fbOddsTable section .match-row .odds {
        gap: 0px;
    }

    .fbOddsTable section.HIL>.header>.odds>div,
    .fbOddsTable section.FHL>.header>.odds>div,
    .fbOddsTable section.FCH>.header>.odds>div,
    .fbOddsTable section.CHL>.header>.odds>div {
        gap: 0;
        justify-items: center;
    }

    .tournTqlTable .tournTqlTableHeader, .tournTqlTable .tournTqlTableBody {
        grid-template-columns: 130px 50px 1fr 1fr 80px 1fr 80px;
    }

    .TQL .padl16{
        padding: 0 12px;
    }
}

@media only screen and (min-width: 1680px){
    section:not(.TTG, .HFT){
        .teamIconSmall {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            .teamIconState{
                display: grid !important;
                column-gap:unset !important;
                grid-template-areas:
                'c a'
                'c b';
                & > :nth-child(1){
                    grid-area: a;
                }
                & > :nth-child(2){
                    grid-area: b;
                }
                & > :nth-child(3){
                    grid-area: c;
                }
                // & > *:only-child{
                //     margin-right: -8px;
                // }
                // column-gap: 8px;
                justify-items: center;
                > * {
                    margin-right: 0 !important;
                    width: 32px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    .fbOddsTable section .match-row .oddsLine {
        // gap: 12px 36px;
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
            .odds{
                width: 100%;
                grid-template-columns: 1fr 1fr 20px ;
                >.hdcOddsItem{
                    row-gap: 8px;
                    .cond{
                        text-align: center;
                        height: 14px;
                    }
                }
            }
        }
    }
}