@import '../../../../css/common-mixin.scss';

.homeRefreshTime {
    display: flex;
    justify-content: flex-end;
    @include font11();
    padding: 0px 0px 12px 0px;
}

.homeRefreshTime .refresh-odds {
    cursor: pointer;
}

:is(.mobileRefreshTime, .homeRefreshTime) .refresh-odds div{
    height: 20px;
    width: 20px;
    margin: 0px 12px;
    background-image: url(../../../../info/Include/images/ic_refresh_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #333333;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(104%) contrast(101%);
    &.rotate {
        animation: rotate 1s linear 1;
    }
}

.FB .mobileRefreshTime .refresh-odds {
    vertical-align: -4px;
    transform: translateY(-1px);
}

.GL .mobileRefreshTime,
.HR .mobileRefreshTime {
    display: flex;
    justify-content: flex-end;
    @include font11();
    padding: 6px 10px;
}

.HR {
    .hasBorderBottom {
        padding: 0 0 6px !important;
        margin: 6px 10px 6px 16px;
        border-bottom: solid 1px #979797;
    }
    .JTCOMBO, .PWIN {
        .hasBorderBottom {
            margin: 6px 10px;
        }
    }
}

.FB .mobileRefreshTime {
    // padding-right: 4px;
    margin-right: -8px;
    @include font11();
    white-space: nowrap;
}