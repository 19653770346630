.RESULTS .icon_flag, .fb-fgsres-table .icon_flag, .CHPRES .icon_flag,
.fbOddsTable section>.header .icon_flag {
    background-image: url(../../info/Football/info/images/icon-fb-header-flag.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.RESULTS .icon_flag, .fb-fgsres-table .icon_flag, .CHPRES .icon_flag {
    margin: 0 auto;
}

.fbOddsTable .icon-clock {
    display: inline-flex;
    justify-content: center;
}

.fbOddsTable section.ALLODDS .icon-clock,
.fbOddsTable section.INPLAY_ALL .icon-clock,
.fbOddsTable section.CRS .icon-clock,
.fbOddsTable section.FCS .icon-clock,
.fbOddsTable section.FGS .icon-clock,
.fbOddsTable section.MSP .icon-clock {
    align-items: center;
}

.fbOddsTable .match-row .icon-clock {
    // margin-top: 4px;
}

/* inplay clock*/
.fbOddsTable .icon-clock .icon_clock_image {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    /* margin-top: -1px; */
}

.fbOddsTable .match-info .icon-clock .icon_clock_image {
    margin-top: 0px;
}

.icon_clock_green {
    background-image: url(../../info/Include/images/icon_clock_green.svg);
}

.icon_clock_red {
    background-image: url(../../info/Include/images/icon_clock_red.svg);
}

.icon-venue>div {
    background-image: url(../../info/Include/images/icon_neutral.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.ic-calender {
    width: 24px;
    height: 24px;
    background-image: url(../../info/Include/images/ic_calendar.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 12px;
    // filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
}

.ic-featuredMatch {
    width: 22px;
    height: 21.5px;
    background-image: url(../../info/Include/images/icon-Football.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 12px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
}

.ic-leagues {
    width: 24px;
    height: 24px;
    background-image: url(../../info/Include/images/ic_leagues_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 8px;
}

.image-H2H {
    background-image: url(../../info/Include/images/ic_h2h.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    cursor: pointer;
    /* margin-left: 6px; */
}

.image-H2H:hover {
    background-image: url(../../info/Include/images/ic_h2h_hover.svg);
}

.image-H2H-item{
    background-image: url(../../info/Include/images/ic_h2h_list.svg);
    height: 34px;
    &:hover{
        background-image: url(../../info/Include/images/ic_h2h_list_hover.svg);
    }
}

.icon-corner {

    background-image: url(../../info/Include/images/icon-corner.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
}

.icon-corner-HT {

    background-image: url(../../info/Include/images/icon-corner-HT.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
}

.icon-corner-FT {

    background-image: url(../../info/Include/images/icon-corner-FT.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
}

.icon-corner-ET {

    background-image: url(../../info/Include/images/icon-corner-ET.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
}


.fbOddsTable{
    .coupon, .coupon-m {
        .corner-result .icon-corner {
        background-image: url(../../info/Include/images/icon-corner.svg);
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
        }
    }
}

.fbOddsTable .icon-clock [data-title]:before,
.fbOddsTable .icon-venue [data-title]:before {
    content: "";
    position: absolute;
    /* right: 11px; */
    bottom: -15px;
    left: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ECF5F0 transparent;
    z-index: 9999;
    visibility: hidden;
}

.fbOddsTable .icon-clock [data-title]:hover:before,
.fbOddsTable .icon-venue [data-title]:hover:before {
    opacity: 1;
    visibility: visible;
}

.fbOddsTable .icon-clock [data-title]:hover:after,
.fbOddsTable .icon-venue [data-title]:hover:after {
    opacity: 1;
    visibility: visible;
}

.fbOddsTable .icon-clock [data-title]:after,
.fbOddsTable .icon-venue [data-title]:after {
    content: attr(data-title);
    background-color: #ECF5F0;
    color: #333333;
    font-size: 13px;
    position: absolute;
    padding: 10px 3px 6px 14px;
    top: 33px;
    left: -15px;
    box-shadow: 3px 12px 16px -4px #878787;
    opacity: 0;
    border: 1px solid #ECF5F0;
    z-index: 99999;
    visibility: hidden;
    display: flex;
    width: 260px;
    min-height: 50px;
    height: auto;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    line-height: 19px;
    white-space: pre-wrap;
    height: fit-content;
    text-align: left;
}

.fbOddsTable .icon-clock [data-title],
.fbOddsTable .icon-venue [data-title] {
    position: relative;
}

.fbOddsTable .icon-liveInfo {
    background-image: url(../../info/Include/images/icon-live.svg);
    -webkit-background-size: 18px 18px;
    -moz-background-size: 18px 18px;
    -o-background-size: 18px 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
    height: 18px;
    width: 18px;
    justify-self: center;
    cursor: pointer;
}

.fbOddsTable .LiveInfo-and-H2H {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .icon-liveInfo-m {
        background-image: url(../../info/Include/images/icon-live-m.svg);
        background-size: 24px 28px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        height: 28px;
        width: 24px;
        margin-right: 8px;
        margin-left: auto;
        &:hover {
            background-image: url(../../info/Include/images/icon-live-m-hover.svg);
        }
    }
    .icon-liveInfo-m + .image-H2H {
        height: 28px;
        background-size: auto;
    }
}


.fbOddsTable .icon-delete {
    background-image: url(../../info/Include/images/delete.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
    justify-self: center;
    cursor: pointer;
}

.fbOddsTable .icon-cross-box .icon-cross {
    content: url(../../info/Include/images/icon-close.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    justify-self: center;
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
}

.fbOddsTable .icon-tick-box .icon-tick {
    content: url(../../info/Include/images/icon-check.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    justify-self: center;
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(157deg) brightness(126%) contrast(100%);
}

.fbOddsTable .icon-cross-box.show {
    background-color: #E10004;
}

.fbOddsTable .icon-tick-box.show {
    background-color: #3FA33A;
}

.fbOddsTable .icon-cross-box.show,
.fbOddsTable .icon-tick-box.show {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fbOddsTable .icon-cross-box.dim,
.fbOddsTable .icon-tick-box.dim {
    background-color: #424242;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fbOddsTable .icon-cross-box {
    border-radius: 4px 0px 0px 4px;
}

.fbOddsTable .icon-tick-box {
    border-radius: 0px 4px 4px 0px;
}