@import './racing.scss';
@import './ewin.scss';
@import './football.scss';
@import './cashout.scss';

@media only screen and (max-width: 1440px) {
    // .topNav ul {
    //     width: calc(100% - ((100% - 72px) / 4 + 16px + 100px + 80px));
    //     // width: 320px;
    // }
    .topNav .dropdown.btn-group button{
        padding: 0px;
    }
    .en .topNav .dropdown.btn-group button{
        white-space: pre-wrap;
        padding: 0px;
        // min-width: 112px;
    }
    .main-content {
        .left-content {
            width: calc(100% - ((100% - 156px) / 4 + 24px));
            // flex: 1;
            .leftMenuMain {
                min-width: calc((100% - 96px) / 9 * 2 + 12px);
                width: calc((100% - 96px) / 9 * 2 + 12px);
            }

            #info_top_block {
                min-width: 222px;
                width: calc((100% - 96px) / 9 * 2 + 12px);
            }

            .rcOddsTable, .fbOddsTable{
                width: calc(100% - ((100% - 96px) / 9 * 2 + 12px) - 12px);
            }
        }
        .betslip-container {
            width: calc((100% - 156px) / 4 + 24px);
        }
    }
    .betslip-width.limit-width {
        min-width: calc((100% - 156px) / 4 + 24px);
        max-width: calc((100% - 156px) / 4 + 24px);
    }
}
