@media only screen and (max-width: 1400px){
    .fbOddsTable section.TTG .match-row,
    .fbOddsTable section.TTG>.header {
        grid-template-columns: [first] 94px [frontEndId] 70px [tourn] 40px 1fr [clock] 70px [odds] minmax(184px, 29%) [h2h] 48px [end];
    }
    .fbOddsTable section.TTG .match-row .odds {
        grid-template-columns: repeat(4, 1fr);
        gap: 16px 0px;
    }
    .fbOddsTable section .match-row {
        &.TTG,&.HFT{
            .match-row > div:not(.odds, .oddsLine, .team){
                min-height: 84px;
            }
        }
        .inplay-clock-con{
            width: 94px;
        }
    }
    .fbOddsTable section > .header > .odds > div div{
        min-width: 40px;
    }


    .fbOddsTable section.HFT>.header,
    .fbOddsTable section.HFT .match-row {
        grid-template-columns: [first] 94px [frontEndId] 70px [tourn] 48px 1fr minmax(240px, 40%) [h2h] 48px [end];
        .odds:not(.hadstopsell) > div{
            width: 20%;
        }
    }
    .fbOddsTable section.TTG .match-row,
    .fbOddsTable section.HFT .match-row{
        .odds{
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 14px 0 14px 12px;
        flex-wrap: wrap;
        height: auto;
        gap: 14px 0;
        }
        .team{
            min-height: 84px;
        }
    }

    // .fbOddsTable section.HIL>.match-row-container>.match-row, .fbOddsTable section.FHL>.match-row-container>.match-row, .fbOddsTable section.CHL>.match-row-container>.match-row {
    //     grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [odds] minmax(182px, 29%) [h2h] 48px [end];
    // }

    // .fbOddsTable section.HIL>.header,
    // .fbOddsTable section.FHL>.header,
    // .fbOddsTable section.CHL>.header {
    //     grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [line] 10% [odds] 19% [h2h]  48px [end];
    // }

    .fbOddsTable section .match-row{
        div.inplay-clock {
            gap: 8px 0;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: center;
            grid-template-columns: 100%;
            padding: 8px 12px;
            .corner-result{
                grid-column: 1/3;
                justify-items: center;
                align-items: center;
                height: auto;
            }
        }
        .exepcted-stop-time {
            margin-right: 6px;
            margin-left: 0;
            width: 22px;
            text-align: left;
        }
    }

    .fbOddsTable {
        section.HIL , section.FHL, section.CHL, section.FCH{
            .match-row {
                align-items: flex-start;
                > div {
                    min-height: 84px;
                }
            }
        }
    }

    .fbOddsTable section:not(.ALLODDS, .INPLAY_ALL, .LASTODDS, .LAST_ODDS, .MIXALLUP_DETAIL, .MIXALLUP_DETAIL_SINGLE) .odds  .oddsCheckboxGrid {
        flex-direction: column;
        row-gap: 8px;
        grid-template-columns: auto;
        justify-items: center;
        align-content: flex-start;
    }

    .oddsArrow:is(.oddsUp, .oddsDown) {
        margin: -4px 0px 2px 0px;
    }

    .fbOddsTable section.MIXALLUP_DETAIL_SINGLE .odds > .oddsCheckboxGrid {
        // display: flex;
        flex-direction:unset;
        gap: 8px;
        // justify-content: flex-start;
        .oddsCheckbox {
            margin-left: 5px;
        }

    }
    .fbOddsTable section > .header, .fbOddsTable section .match-row {
        grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 70px [odds] 28% [h2h] 48px [end];
    }

    // .fbOddsTable section > .header, .fbOddsTable section .match-row{
    //     grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [odds] 25% [h2h] 48px [end];
    // }

    .fbOddsTable section.INPLAY>.header,  .fbOddsTable section.INPLAY .match-row-container>.match-row {
        .date{
            width: 94px;
        }
        .fb-id{
            width: 64px;
        }
        .inplay-clock-con{
            width: 94px;
        }
        // .odds{
        //     width: 26%;
        // }
    }

    .fbOddsTable section.CRS>.header,
    .fbOddsTable section.CRS .match-row,
    .fbOddsTable section.FCS>.header,
    .fbOddsTable section.FCS .match-row {
        grid-template-columns: [first] 94px [frontEndId] 70px [tourn] 40px 1fr [clock] 70px [odds] 30% [h2h] 48px [end];
    }

    .tournTqlTable .tournTqlTableHeader, .tournTqlTable .tournTqlTableBody {
        grid-template-columns: 126px 40px 1fr 1fr 80px 1fr 80px;
    }

    .TQL .padl16{
        padding: 0 8px;
    }

    .fbOddsTable section .match-row .oddsLine {
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
            .odds{
                >.hdcOddsItem{
                    .cond{
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
}