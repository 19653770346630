@import '../../common-mixin.scss';

.mixallup-detail-calculator .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 49px;
    align-items: center;
    background-color: white;
}

.mixallup-detail-calculator .header .left {
    color: #333333;
    padding-left: 6px;
    cursor: pointer;
}

.mixallup-detail-calculator .refresh-time-container {
    display: flex;
    justify-content: right;
    padding-top: 12px;
    padding-right: 16px;
}

.mixallup-detail-calculator .content {
    background-color: #EAEAEB;
    min-height: 148px;
    padding: 15px 12px 8px 12px;
    @include font13();
    .quick-select {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #D4D4D4;
        padding-bottom: 8px;
        color: #333;
        font-size: 13px;
        font-weight: 500;
        padding-right: 2px;
        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.mixallup-detail-calculator .footer {
    padding: 0px;
    height: 128px;
    margin: 0px;
}

.mixallup-detail-calculator .odds-table {
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    // word-break: break-word;
    display: grid;
    color: #333333;
    @include font13();
    @include lineHeight15();
    padding-top: 12px;
}

.mixallup-detail-calculator .odds-table>div {
    display: grid;
    grid-template-columns: 26px auto;
    align-items: flex-start;
    margin-bottom: 13px;
    cursor: pointer;
}


.mobile .mixallup-detail-calculator .odds-table>div {
    align-items: center;
}

section.MIXALLUP_DETAIL .team {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 30px;
    width: 100%;
    line-height: 19px;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
    &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.ECD{
        grid-template-columns: 1fr 1fr;
    }
}



section.MIXALLUP_DETAIL .coupon:not(:first-child) {
    margin-top: 0;
}

section.MIXALLUP_DETAIL .match-row-container>.match-row-m.matrix:nth-child(odd) {
    background-color: #f4f4f4;
}

section.MIXALLUP_DETAIL .match-row-container>.match-row-m.matrix:nth-child(even) {
    background-color: #fff;
}

section.MIXALLUP_DETAIL .oddsHAD,
section.MIXALLUP_DETAIL .oddsEHA,
section.MIXALLUP_DETAIL .oddsFHA,
section.MIXALLUP_DETAIL .oddsHHA,
section.MIXALLUP_DETAIL .oddsFTS {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 32px;
    width: 100%;
    text-align: center;
    /* background-color: #f4f4f4; */
    justify-items: center;
    background-color: #fff;
}

section.MIXALLUP_DETAIL .oddsFTS {
    background-color: #fff;
}

section.MIXALLUP_DETAIL .oddsLine {
    display: grid;
    grid-template-columns: 33% 67%;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;
}


section.MIXALLUP_DETAIL .oddsLine .arrow-small {
    display: none;
}

section.MIXALLUP_DETAIL .oddsLine:nth-child(even) {
    background-color: #f4f4f4;
}

section.MIXALLUP_DETAIL .oddsLine .odds {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    justify-items: center;
}

/* section.MIXALLUP_DETAIL .match-info{
    margin-top: 10px;
} */

section.MIXALLUP_DETAIL_SINGLE {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    // overflow: hidden;
    margin-top: 10px;
}
.MIXALLUP_DETAIL section.MIXALLUP_DETAIL_SINGLE .coupon{
    border-radius: 0;
}


section.MIXALLUP_DETAIL .oddsHDC {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 8px 0px;
    width: 100%;
    text-align: center;
    background-color: #f4f4f4;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

section.MIXALLUP_DETAIL .oddsHDC>div:first-child {
    display: flex;
    flex-direction: column;
}

section.MIXALLUP_DETAIL .match-row-container .odds.oddsHDC span:hover {
    cursor: initial;
    text-decoration: none;
}

section.MIXALLUP_DETAIL .team.HFT {
    display: none;
}

.MIXALLUP_DETAIL .oddsHFT {
    display: grid;
    grid-template-columns: repeat(9, 11%);
    gap: 0px;
    justify-content: center;
    margin-left: 6px;
}

.MIXALLUP_DETAIL .odds.oddsHFT>div {
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    align-items: center;
}

.MIXALLUP_DETAIL .oddsHFT>div>div {
    padding: 4px 0px;
    text-align: center;
}

.MIXALLUP_DETAIL .oddsHFT .oddsCheckbox {
    text-align: center;
    margin-left: 4px;
}

section.MIXALLUP_DETAIL .oddsOOE {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    line-height: 32px;
    width: 100%;
    text-align: center;
    background-color: #fff;
    justify-items: center;
    padding-bottom: 6px;
}

section.MIXALLUP_DETAIL .oddsOOE {
    grid-template-columns: 50% 50%;
}

section.MIXALLUP_DETAIL .team.OOE{
    grid-template-columns: 50% 50%;
    background-color: #fff;
    height: auto;
    padding-top: 6px;
}

.mobile section.MIXALLUP_DETAIL .odds>div{
    align-items: center;
}
.mobile .mixallup-detail-calculator{
    // margin-top: 12px;
}
.mobile .mixallup-detail-calculator .header{
    background-color: #333;
}
.mobile .mixallup-detail-calculator .header .dd-arrow{
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

.mobile .mixallup-detail-calculator .header .left > div:last-child{
    // position: absolute;
    display: inline;
    width: 100%;
    @include font15();
    font-weight: 500;
    color: white;
    // padding-left: 7px;
    line-height: 24px;
}

.mobile .m-hueRotate{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(137%) contrast(101%);
}

.mobile .mixallup-detail-calculator .odds-table{
    grid-template-columns: 50% 50%;
}

.mobile .mixallup-detail-calculator .oddsCheckbox input[type=checkbox]+span{
    border: unset;
}

.MIXALLUP_DETAIL .oddsTTG,
.MIXALLUP_DETAIL .oddsETG{
    display: grid;
    grid-template-columns:  repeat(8, 12.5%);
    gap: 0px;
}

.MIXALLUP_DETAIL .odds.oddsTTG>div,
.MIXALLUP_DETAIL .odds.oddsETG>div {
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    text-align: center;
}

section.MIXALLUP_DETAIL .odds.oddsTTG .oddsCheckbox,
section.MIXALLUP_DETAIL .odds.oddsETG .oddsCheckbox {
    text-align: center;
    line-height: 29px;
    display: inline-block;
}

section.MIXALLUP_DETAIL .team.TTG,
section.MIXALLUP_DETAIL .team.ETG {
    display: none;
}

section.MIXALLUP_DETAIL .match-row-container .odds span:hover {
    cursor: initial;
    text-decoration: unset;
}

section.MIXALLUP_DETAIL .dropdown-toggle:after{
    display: none;
}


section.MIXALLUP_DETAIL .match-info .scoring-info>div:last-child:hover{
    text-decoration-line: underline;
}

section.MIXALLUP_DETAIL .match-info .scoring-info>div:last-child button section.INPLAY_ALL .match-info .status>div:last-child button {
    line-height: 0px;
    padding: 0px;
    height: 12px;
}
section.MIXALLUP_DETAIL .team.FGS{
    grid-template-columns: 50% 50%;
}

.allup-cal-panel {
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 212px) 212px;
    padding: 12px;
    color: #333333;
}

.allup-cal-panel-result {
    display: grid;
    grid-template-columns: repeat(4, 25%);
}

.allup-cal-panel-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allup-cal-panel-btn>div {
    margin-right: 0;
}

.allup-cal-panel-input {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px;
    color: #333333;
}

.allup-formula-dd, .allup-formula-input {
    display: flex;
    align-items: center;
}

.allup-cal-alert {
    width: 100%;
    background-color: #f4f4f4;
    color: red;
    padding: 4px 0px 14px 10px;
    position: relative;
}

.allup-cal-panel-input input[type=text] {
    padding-left: 8px;
    box-sizing: border-box;
    border: 1px solid #757575;
    outline: none;
    border-radius: 2px;
    width: 120px;
    line-height: 22px;
    @include font15();
}

.allup-cal-panel-input .dropdown-toggle::after {
    display: none;
}

.allup-cal-panel-input #ddAllupFormula {
    width: 120px;
    border: 1px solid #757575;
    height: 28px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
}

.allup-cal-out>div:first-child {
    // padding: 0px 0px 12px 0px;
    padding-bottom: 8px;
    font-weight: 500;
    line-height: 12px;
    font-size: 11px;
}

.allup-cal-quick-btn-set>div {
    display: grid;
    grid-template-columns: 84px 84px;
}

.allup-cal-quick-btn {
    border: 1px solid #405A55;
    border-radius: 4px;
    text-align: center;
    margin: 6px;
    padding: 2px;
    cursor: pointer;
    color: #405A55;
    width: 72px;
    @include font15();
    line-height: 22px;
}

.allup-cal-out>div:first-child {
    // color: #569171;
}

.allup-cal-out>div:nth-child(2) {
    color: #405A55;
    @include font15();
    line-height: 16px;
}


section.MIXALLUP_DETAIL .coupon-m .desc{
    flex-basis: auto;
}

.MIXALLUP_DETAIL .title-m {
    display:none;
}

.mobile.fbOddsTable section.MIXALLUP_DETAIL .oddsHDC > div{
    flex-direction: column;
    align-items: center;
}