@import '../../common-mixin.scss';

.fbOddsTable section.MIXALLUP .match-row-container {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    background-color: white;
    box-shadow: var(--box-shadow) ;
}

.fbOddsTable section.MIXALLUP .match-row-container .match-row {
    /* grid-template-columns: 40px 126px 67px 48px 516px; */
    // grid-template-columns: 10% 20% 20% 10% 0.8fr;
    background-color: white;
    align-content: center;
}
.fbOddsTable section.MIXALLUP .match-row-container .match-row:last-child{
    border-radius:  0 0 4px 4px;
}

.fbOddsTable section.MIXALLUP .match-row-container>.match-row:nth-child(2n+2) {
    background-color: #f4f4f4;
}

.fbOddsTable section.MIXALLUP .match-row-container>.match-row:nth-child(2n+2)>div {
    position: relative;
}
.fbOddsTable section.MIXALLUP > .header > :first-child{
    width: 42px;
}

.fbOddsTable section.MIXALLUP .match-row .icon-venue {
    display: inline-block;
}

.fbOddsTable section.MIXALLUP .match-row .icon-venue>div {
    margin-top: 0px;
}

.fbOddsTable header.MIXALLUP .date-competition-selection section.section-OFM {
    display: none;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-container {
    grid-template-columns: 100%;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-container .match-row-m {
    grid-template-columns: 100%;
    grid-template-columns: 90% 10%;
}


.fbOddsTable.mobile section.MIXALLUP .match-row-container .match-row-m>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-container .match-row-m>div:first-child>div {
    // width: 100%;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-container .match-row-m>div:first-child>div:first-child {
    margin-left: 9px;
    margin-top: 5px;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-container .match-row-m>div:first-child>div:first-child>*{
    margin-right: 5px;
    display: inline;
}

.fbOddsTable.mobile section.MIXALLUP .match-row-m .team{
    width: 220px;
}

.fbOddsTable.mobile>.allup-control{
    display: none;
}

.fbOddsTable section.MIXALLUP .match-row>div:last-child{
    display: flex;
}

.fbOddsTable section.MIXALLUP .match-row-m .icon-venue{
    display: flex;
    align-items: center;
}

.fbOddsTable .allup-next {
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    align-content: center;
    padding-left: 18px;
    @include font13();
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    .is-floating &{
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.fbOddsTable .is-floating .allup-next {
    background-color: rgba(255, 255, 255, 0.95);
}
.fbOddsTable .MIXALLUP{
    .oddsLine{
        flex:1 0;
        width: auto;
    }
}