
@import '../../common-mixin.scss';
section.CHP {
    margin-bottom: 12px !important;
}
.CHP .tournHeader {
    border-radius: 4px 4px 0px 0px;
}
.ALLODDS .CHP .tournHeader {
    margin-top: 0;
}
.Tournament .CHP .tournHeader {
    margin-top: 0;
    border-radius: 0;
}

.fbOddsTable section.CHP .tournHeader .coupon {
    background-color: #FFFFFF;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0px 12px 0px 0px;
}

.fbOddsTable section.CHP .tournHeader .desc span{
    display: flex;
    align-items: center;
}

.tournHeader {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    background-color: #FFFFFF;
    padding: 8px 0px;
}

.tournName {
    padding: 0px 0px 0px 12px;
    display: flex;
    align-items: center;
}

.tournEsst {
    text-align: right;
    margin-left: auto;
    margin-right: 24px;
    /* text-align: right;
    margin-left: 12px;
    display: flex;
    gap: 78px; */
}

.tournButton {
    justify-self: flex-end;
    margin: 0px 12px 0px 0px;
}

.tournEsstLbl {
    @include font11();
}

.tournSelections2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr 20px 10%);
    box-shadow:  var(--box-shadow);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.tournSelections {
    display: grid;
    grid-template-columns: repeat(4, 1fr 20px 9%); //SQ0248-1893
    box-shadow:  var(--box-shadow);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.tournSelections > div, .tournSelections2 > div {
    display: flex;
    align-items: center;
}
.tournSelections> :last-child, .tournSelections2> :last-child{
    border-radius: 0 0 4px 4px;
}

.tournSelHeader {
    padding: 8px 12px;
    background-color: #EAEAEA;
    height: 34px;
    @include H6();
}

.tournSelHeaderOdds {
    padding: 8px 0px;
    background-color: #EAEAEA;
    @include H6();
}

.tournSelName {
    padding: 14px 12px;
    word-break: break-word;
    min-height: 44px;
    // color: #405A55;
    @include H5();
}

.tournSelChkbox {
    padding: 8px 0px;
    justify-content: center;
}

.tournSelOdds {
    display: flex;
    padding: 8px 0px;
    word-break: break-word;
    // color: #405A55;
    @include H5();
    position:relative;
    flex-flow: column-reverse;
    justify-content: center;
    .oddsArrow:is(.oddsUp, .oddsDown)  {
        margin: 0 0 2px 0;
    }
    & > .odds {
        padding: 0 6px;
    }
}

.tournSelOdds::after {
    position: absolute;
    display: block;
    content: '';
    right: 0;
    top: 3px;
    bottom: 3px;
    width: 1px;
    background-color: #e7e7e7;
}


.gpwSelOdds .oddsArrow.oddsUp,
.gpwSelOdds .oddsArrow.oddsDown,
.gpfSelOdds .oddsArrow.oddsUp,
.gpfSelOdds .oddsArrow.oddsDown,
.TSP .tspSelOdds .oddsArrow.oddsUp,
.TSP .tspSelOdds .oddsArrow.oddsDown {
    margin: 4px 0px 0px 4px;
}

.CHP .coupon, .TQL .coupon, .GPW .coupon, .GPF .coupon, .TPS .coupon, .TSP .coupon {
    margin-top: 12px;
    border-radius: 4px 4px 0px 0px;
}

.GPW .masonry-col>div:nth-child(1), .GPW .masonry-col>div:nth-child(2) {
    width: calc(33% - 12px);
    margin-right: 12px;
}

.GPW .masonry-col>div:last-child {
    width: calc(33% - 12px);
    margin-right: 12px;
}

.ALLODDS .CHP .oddsCheckbox input[type=checkbox]:checked+span,
.INPLAY_ALL .CHP .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 8px;
    padding-left: 8px;
}

.CHP .oddsCheckbox input[type=checkbox]:checked+span,
.GPW .oddsCheckbox input[type=checkbox]:checked+span,
.TPS .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 10px;
}

.GPF .oddsCheckbox input[type=checkbox]:checked+span,
.TSP .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 10px;
    padding-right: 4px;
}

.CHP .odd, .TPS .odd {
    background-color: #FFFFFF;
}

.GPW .even, .GPF .even, .gpfRow, .TPS .even, .CHP .even {
    background-color: #F4F4F4;
}

.gpfRoweven {
    background-color: #EAEAEA;
}

.gpwSingleTable {
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 12px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.gpwTitle {
    padding: 8px;
}

.gpwEsst {
    padding: 8px;
    border-top: 1px #c4c4c4 solid;
}

.gpwEsstLbl {
    @include font11();
}

.gpwSelections {
    display: grid;
    grid-template-columns: 1fr 14px 38%;
}

.gpwSelHeader {
    background-color: #EAEAEA;
    @include font11();
    padding: 8px;
}

.gpwSelName {
    padding: 8px;
}

.gpwSelChkbox {
    padding: 8px 0px;
}

.gpwSelOdds {
    display: flex;
    padding: 8px 0px 8px 10px;
}

.showAllRow {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 0px 0px 4px 4px;
    justify-content: space-between;
    padding: 5px 12px;
    box-shadow: var(--box-shadow);
}

.fbMiddle .chooseModelBtn{
    border-radius: 4px;
    background: #B4B4B4;
    color: #FFF;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
  }

.fbMiddle .chooseModelBtn div{
    width: 76px;
    height: 36px;
    word-wrap:break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.fbMiddle .chooseModelBtn .active{
    background:   #00C06C;
}

.gpfSingleTable {
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 12px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
}

.gpfTitle {
    padding: 8px;
}

.gpfEsst {
    padding: 8px;
    display: flex;
}

.gpwEsstLbl {
    @include font11();
    padding-right: 4px;
}

.gpfHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #c4c4c4 solid;
}

.gpfSelections2 {
    display: grid;
    grid-template-columns: 34% 33% 33%;
}

.gpfSelections3 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.gpfSelections4 {
    display: grid;
    grid-template-columns: 20% repeat(4, 20%);
}

.gpfSelections5 {
    display: grid;
    grid-template-columns: 20% repeat(5, 16%);
}

.gpfSelections6 {
    display: grid;
    grid-template-columns: 22% repeat(6, 13%);
}

.gpfSelections7 {
    display: grid;
    grid-template-columns: 23% repeat(7, 11%);
}

.gpfSelections8 {
    display: grid;
    grid-template-columns: 20% repeat(8, 10%);
}

.gpfSelHeader {
    padding: 8px 0px;
    text-align: center;
}

.gpfSelRowHeader {
    padding: 8px;
}

.gpfStickyCol {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 10;
}

.gpfCell {
    padding: 8px 0px;
    text-align: center;
}

.gpfCellGrid {
    display: grid;
    padding: 8px 0px;
    grid-template-columns: 40% 60%;
    align-items: center;
}

.gpfFirstCell {
    background: linear-gradient(to top right, #F4F4F4 calc(50% - 1px), #F4F4F4, #FFFFFF calc(50% + 1px) )
}
.gpfFirstCell:before {
    content : "";
    position: absolute;
    top    : 4px;
    right  : 0;
    height  : 80%;
    width   : 1px;
    border-right:1px solid #B4B4B4;
}
.gpfWinner {
    @include font11();
    text-align: right;
    padding: 4px 8px 0px 0px;
}
.gpfRunnerUp {
    @include font11();
    padding: 0px 0px 4px 8px;
}

.gpfSelChkbox {
    text-align: right
}

.gpfSelOdds {
    display: flex;
    padding-left: 4px;
    flex-direction: column;
    width: fit-content;
    align-items: center;
}

.tspTable {
    /* margin-bottom: 4px; */
    background-color: #FFFFFF;
    color: #405A55;
}
.tspTable:last-child{
    border-radius: 0  0 4px 4px;
}
.tspTable>div:not(.tspHeader){
    /* padding: 0 8px; */
}

.tspHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #F4F4F4; */
    padding: 8px;
    padding-left: 6%;
}

.tspTitle {
    display: flex;
    gap: 12px;
}

.tspEsst {
    min-width: 100px;
    text-align: right;
}

.tspEsstLbl {
    @include font11();
}

/* .tspSelections1 {
    display: grid;
    grid-template-columns: 100%;
} */

.tspSelections2 {
    display: grid;
    grid-template-columns: 50% 50%;
}

/* .tspSelections3 {
    display: grid;
    grid-template-columns: repeat(3, 33%);
}

.tspSelections4 {
    display: grid;
    grid-template-columns: repeat(4, 25%);
}

.tspSelections5 {
    display: grid;
    grid-template-columns: repeat(5, 20%);
}

.tspSelections6 {
    display: grid;
    grid-template-columns: repeat(6, 16%);
} */

.tspCell {
    border-right: 1px solid #ebebeb;
    margin: 10px 0px;
}

.tspCell:last-child {
    border-right: 0;
}

.tspAns {
    /* text-align: center; */
    @include H5();
}

.tspCellGrid {
    display: grid;
    grid-template-columns: 1fr 20px 42px;
    padding: 8px 12%;
    align-items: center;
}

.tspAns {
    /* grid-column: 1 / 3; */
    /* padding: 0px 0px 8px 0px; */
}

.tspSelChkbox {
    text-align: right;
}

.tspSelOdds {
    display: flex;
    padding: 0px 0px 0px 8px;
    align-items: center;
    @include H5();
}

.chpres-table {
    display: grid;
    grid-template-columns: 15% 10% 30% 45%;
    align-items: center;
}


.chpres-table .event-id, .chpres-table .flag-cell {
    text-align: center;
}

.chpres-table .tourn-header {
    @include font11();
    padding: 10px 0px;
}

.chpres-table .team-name {
    padding-left: 8px;
}

.chpres-table .tourn-cell {
    vertical-align: middle;
    position: relative;
    min-height: 44px;
    height: auto;
    padding: 14px 8px 16px 8px;
    word-break: break-all;
}

.chpres-table .tourn-cell.flag-cell {
    padding: 12px 0px 0px 0px;
}

.chpres-table.head {
    background-color: #EAEAEA;
}

.chpres-table.odd {
    background-color: #FFFFFF;
}

.chpres-table.sec {
    background-color: #F4F4F4;
}

.CHPRES .cell-border::before,
.TOURNRES .cell-border::before {
    content: '';
    width: 1px;
    height: 70%;
    position: absolute;
    background: #e7e7e7;
    top: 15%;
    left: 0;
}

.tourn-result-header {
    display: none;
}

.chpres-line {
    width: 100%;
    border-top: 1px solid #B4B4B4;
    margin: 12px 0px;
}

.fbOddsTable header.TOURNRES>.fbTitle {
    border-radius: 4px;
}

.tourn-result-table {
    width: 100%;
    background-color: #FFFFFF;
}

.tourn-result-table2 {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table3 {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-gp1 {
    display: grid;
    grid-template-columns: 10% 40% 50%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-gp2 {
    display: grid;
    grid-template-columns: 10% 40% 25% 25%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-gp3 {
    display: grid;
    grid-template-columns: 10% 20% 20% 20% 30%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-gp-m {
    display: grid;
    grid-template-columns: 20% 30% 50%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-tsp {
    display: grid;
    grid-template-columns: 10% 40% 50%;
    align-items: stretch;
    background-color: #FFFFFF;
}

.tourn-result-table-tsp-m {
    display: block;
    background-color: #FFFFFF;
}

.TOURNRES .header {
    background-color: #FFFFFF;
    text-align: center;
    @include font11();
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TOURNRES .cell {
    color: #333333;
    text-align: center;
    padding: 8px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TOURNRES .grey {
    background-color: #F4F4F4;
}

.TOURNRES .tourn-result-left {
    align-items: flex-start;
    padding-left: 12px;
    text-align: left;
}

.TOURNRES .gpf-result-groupno, .TOURNRES .gpf-result-group {
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TOURNRES .gpf-result-refund {
    grid-row: 1 / 3;
    grid-column: 5 / 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TOURNRES .gpf-result-wincomb {
    grid-column: 3 / 5;
    position: relative;
}

.TOURNRES .gpf-result-wincomb::after {
    content: '';
    width: 98%;
    height: 1px;
    background-color: #b7b7b7;
    position: absolute;
    left: 1%;
    bottom: 0;
}

.TOURNRES .gp-small-title {
    @include font11();
}

.TOURNRES .gp-small-title-padding {
    @include font11();
    padding-top: 8px;
}

.TOURNRES .tourn-result-table-tsp-answer {
    width: 100%;
    display: grid;
    grid-template-columns: 12% 88%;
}

.CHP .acceptInplayOnly {
    display: flex;
    width: 100%;
    height: 44px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

.TQL .acceptInplayOnly {
    grid-column: 4 / 8;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CHP .acceptInplayOnly .dd-arrow, .TQL .acceptInplayOnly .dd-arrow {
    margin-left: 16px;
    border-color: #00c06c;
}