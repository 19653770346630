@import './layout_gt1440/index.scss';
@import './layout_1280to1440/index.scss';
@import './layout_lt1400/index.scss';
@import './layout_1024to1279/index.scss';
@import './layout_320to768/index.scss';
@import './layout_1680up/index.scss';


.StandardPage {
    min-width: 1025px;
}

.StandardPage-scrollbars>div:nth-child(n+2) {
    z-index: 9000;
}

// test for po
.StandardPage-scrollbars>div:nth-child(1) {
    overflow: auto !important;
    margin-right: unset !important;
    margin-bottom: unset !important;
}

.StandardPage-scrollbars>div:nth-child(2) {
    z-index: -999;
}

.StandardPage-scrollbars>div:nth-child(3) {
    z-index: -999;
}

@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .StandardPage {
            min-width: initial;
        }
        #divTopBanner {
            height:  auto !important;
        }
    }
}


@media not screen and (max-width: 1023px) and (pointer: coarse) {
    :where(.isDeviceVersion) {
    .sticky-inner-wrapper {
        position: relative !important;
        transform: translate3d(0px, 0px, 0px) !important;
    }
    }
}
