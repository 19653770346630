.fbOddsTable section.HDC .match-row .odds,
.fbOddsTable section.HDC>.header>.odds>div {
    // grid-template-columns: [first] 58px 58px [end];
    padding-right: 0;
}


.fbOddsTable section.INPLAY_ALL .oddsEDC,
.fbOddsTable section.INPLAY_ALL .oddsFHH,
.fbOddsTable section.INPLAY_ALL .oddsHDC,
.fbOddsTable section.ALLODDS .oddsHDC,
.fbOddsTable section.ALLODDS .oddsFHH,
.fbOddsTable section.ALLODDS .oddsEDC,
.fbOddsTable section.LAST_ODDS .oddsHDC,
.fbOddsTable section.LAST_ODDS .oddsFHH,
.fbOddsTable section.LAST_ODDS .oddsEDC,
.fbOddsTable section.MIXALLUP_DETAIL .oddsEDC
 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    text-align: center;
    background-color: #fff;
    justify-items: center;
    align-items: center;
    justify-content: center;
    padding: 9px 12px;
}

.fbOddsTable section.INPLAY_ALL .oddsHDC>div:first-child,
.fbOddsTable section.ALLODDS .oddsHDC>div:first-child,
.fbOddsTable section.LAST_ODDS .oddsHDC>div:first-child,
.fbOddsTable section.INPLAY_ALL .oddsFHH>div:first-child,
.fbOddsTable section.ALLODDS .oddsFHH>div:first-child,
.fbOddsTable section.LAST_ODDS .oddsFHH>div:first-child,
.fbOddsTable section.INPLAY_ALL .oddsEDC>div:first-child,
.fbOddsTable section.ALLODDS .oddsEDC>div:first-child,
.fbOddsTable section.LAST_ODDS .oddsEDC>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.fbOddsTable section.INPLAY_ALL .match-row-container .odds.oddsHDC span.team-name:hover,
.fbOddsTable section.ALLODDS .match-row-container .odds.oddsHDC span.team-name:hover,
.fbOddsTable section.LAST_ODDS .match-row-container .odds.oddsHDC span.team-name:hover,
.fbOddsTable section.INPLAY_ALL .match-row-container .odds.oddsEDC span.team-name:hover,
.fbOddsTable section.ALLODDS .match-row-container .odds.oddsEDC span.team-name:hover,
.fbOddsTable section.LAST_ODDS .match-row-container .odds.oddsEDC span.team-name:hover {
    cursor: initial;
    text-decoration: none;
}