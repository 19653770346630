.button {
    border-radius: 16px;
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.login-page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    min-width: 1024px;
}

.Ewin2Footer {
    margin-top: auto;
    background-color: white;
    width: 100%;
    height: 50px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .copyRight {
        flex: 0 0 80%;
        text-align: right;
    }
}

.Ewin2Header {
    display: flex;
    justify-content: flex-end;
    background-color: #173e96;
    height: 32px;
    padding-right: 26px;
    align-items: center;
}

.Ewin2Header-m {
    height: 50px;
    background-color: #003c84;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        height: 42px;
    }
    .topMenuIcon {
        content: url(../../../info/Include/images/lang.svg) !important;
    }

    .topMenuLang {
        color: #FFFFFF !important;
        font-weight: 400;
    }
}

.logo-banner {
    height: 64px;
    background-color: white;
    border-bottom: #003c84 solid 2px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    img {
        height: 42px;
    }
}

#eod .eod {
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgb(0, 0, 0, 0.3);
    position: absolute;
    border-radius: 15px 0px 0px 15px;
    display: none;
}

#eod .show {
    display: block;
}

#eod .eod-msg {
    margin-top: 366px;
    height: 180px;
    width: 400px;
    padding: 40px;
    border-radius: 15px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 15px;
    text-align: center;
    line-height: 21px;
}

.eod-msg div {
    position: relative;
    width:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ssoLogoutBtn {
    background-color: #003c84 !important;
}

.login-body {
    width: 100%;
    max-width: 1150px;
    margin: 50px auto;
    padding-bottom: 36px;
    border-radius: 15px;
    box-shadow: var(--box-shadow);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;

    .ewin {
        margin-top: 24px;
        margin-bottom: 4px;
    }

    .title {
        opacity: 1;
        color: rgba(51, 51, 51, 1);
        font-size: 40px;
        line-height: 42px;
        margin-top: 52px;
    }
    .title-hr {
        margin-top: 16px;
        background-color: #fecf13;
        width: 120px;
        height: 4px;
    }
    .explanation {
        margin-top: 20px;
        max-width: 600px;
        color: rgba(51, 51, 51, 1);
        font-size: 15px;
        text-align: center;
        line-height: 1.4;
    }
    .login-c {
        margin: 46px auto 100px auto;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        width: min(900px, 100%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: min(100%, 438px);
            object-fit: contain;
            margin-top: 20px;
        }
    }

    .login {
        min-height: 300px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .login-t {
            margin-top: 32px;
            opacity: 1;
            color: rgba(51, 51, 51, 1);
            font-size: 40px;
            line-height: 42px;
            margin-bottom: 24px;
        }
        .input-c {
            width: 90%;
        }

        .login-input {
            height: 40px;
            width: 100%;
            font-size: 13px;
            padding: 13px 12px;
        }
        .usename {
            margin-bottom: 20px;
        }

        .password {
            margin-bottom: 24px;
        }

        .login-button {
            color: #122c68;
            background-color: #fecf13;
            width: 200px;
            &:hover {
                background-color: #e6bb11;
            }
        }
    }
    .link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
        font-size: 13px;
        color: #333333;
        width: 100%;
        .item {
            display: flex;
            align-items: center;
            flex: 1;
        }
        .item1 {
            border-right: #b4b4b4 solid 1px;
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;
            .pre {
                white-space: pre-wrap;
            }
            .tooltips {
                position: absolute;
                height: 105px;
                width: 220px;
                background-color: white;
                top: -120px;
                right: -22px;
                border-radius: 10px;
                box-shadow: 0px 2px 5px rgb(0 0 0 / 36%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                div {
                    flex: 1 1 50%;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    width: 100%;
                    color: #333333;
                    &:first-child {
                        border-bottom: #b4b4b4 solid 1px;
                    }
                    text-align: center;
                }
                &::before {
                    content: '';
                    position: absolute;
                    border-color: #fff;
                    border-style: solid;
                    border-width: 12px;
                    left: calc(50% - 12px);
                    top: calc(100% - 18px);
                    transform: rotate(45deg);
                    box-shadow: 2px 2px 5px rgb(0 0 0 / 36%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    border-color: #fff;
                    border-style: solid;
                    border-width: 20px;
                    left: calc(50% - 20px);
                    top: calc(100% - 40px);
                }
            }
            &.show {
                color: #003c84;
            }
        }
        .item2 {
            justify-content: flex-start;
            padding-left: 30px;
        }
    }
}

@media (min-width: 320px) {
    .modal-dialog.custom-modal {
        margin: 0.5rem 0.6rem;
    }
}

@media (min-width: 576px) {
    .modal-dialog.modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px) {
    .modal-dialog.custom-modal {
        max-width: 600px;
    }
}

.modal-dialog.custom-modal {
    font-size: 13px;
    color: #333333;
    .modal-content {
        border-radius: 16px;
    }
    .modal-header {
        border-bottom: none;
        padding: 24px 24px 0 24px;
    }

    .modal-title {
        font-size: 24px;
    }
    .btn-close {
        opacity: 0.8;
    }

    .modal-body {
        padding: 24px;
    }

    .button-c {
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &.answer-modal {
        // .answer {
        //     margin-top: 16px;
        //     margin-bottom: 60px;
        //     width: 100%;
        //     height: 40px;
        // }

        .login-answer-title {
            display: none;
        }

        .login-answer-container {
            .login-answer-content {
                padding: 0;
                border-radius: 0;
                .answer-input input {
                    height: 40px;
                    color: #333333;
                }
                .button-box {
                    display: flex;
                    gap: 24px;
                    justify-content: center;
                    align-items: center;
                    div {
                        width: 100px;
                        height: 28px;
                        font-weight: normal;
                        &:first-child{
                            background-color: #003c84;
                        }
                    }
                    .ewallet_upgrade {
                        width: 120px;
                    }
                }
            }
        }
    }

    &.tc-modal {
        .proceed-button {
            color: #122c68;
            background-color: #fecf13;
            width: 100px;
        }
        .tc-content {
            height: 440px;
            color: #333333;
            font-size: 13px;
            line-height: 18px;
            // position: relative;
            .tandCTextRow {
                display: grid;
                grid-template-columns: 33px auto;
            }
            &::after {
                content: ' ';
                display: block;
                height: 8px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
                position: sticky;
                bottom: 0;
            }
            .tandCTb {
                height: 100%;
            }
        }
    }

    &.incorrect-modal {
        .incorrect-content {
            margin-bottom: 22px;
        }
        .cancel-button {
            color: #ffffff;
            background-color: rgba(97, 118, 160, 1);
            width: 100px;
            &:hover {
                background-color: #003c84;
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (pointer: coarse){
    .login-page {
        min-width: initial;
    }
    .isSpeedbet .login-body .login-c {
        border-bottom: none;
    }
    .login-body {
        width: calc(100% - 24px);
        margin: 30px 12px;
        padding: 0 20px;
        .title {
            font-size: 24px;
            margin-top: 24px;
        }
        .title-hr {
            margin-top: 2px;
        }

        .explanation {
            font-size: 13px;
            // padding: 0 24px;
        }

        .login-c {
            margin: 0 24px 24px 24px;
            border-top: none;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }

        .image {
            width: 100%;
            img {
                margin-top: 0;
            }
        }

        .login {
            min-height: 250px;
            width: 100%;

            .input-c {
                width: 100%;
            }

            .login-t {
                margin-top: 16px;
                font-size: 24px;
                margin-bottom: 12px;
            }
        }

        .link {
            margin-bottom: 24px;
            .item1 {
                padding-right: 20px;
                .tooltips {
                    right: -45px;
                }
            }

            .item2 {
                padding-left: 9px;
            }
        }
    }
}

body.modal-open {
    overflow: initial !important;
    padding-right: inherit !important;
}

body:has(.login-page) {
    padding-bottom: 0;
}

.GL.login-page .topInfoMenu .topNav ul {
    max-width: unset;
    gap: 0 32px;
    // li {
    //     max-width: unset;
    // }
}