@media screen and (max-width: 1279px) {


    .MRTable-container{
        .hafu-results-table{
            .hafu-results-dividendInfo{
                & > div{
                    padding: 6px 4px;
                }
            }
        }
    }

    .allup-cal-panel-set {
        padding: 0px 20px;
    }
    .allup-cal-panel-result{
        grid-template-columns: 1fr repeat(3, 2fr);
        margin-right: 34px;
        gap: 12px;

        &>div{
            min-width: 80px;
            >:first-child{
                height: 36px;
            }
        }
        >:first-child{
            min-width: 42px;
        }
    }

    .fb-results-match-results {
        .table-row{
            &>.table-cell{
                padding: 8px 4px;
                &.detail-cell,
                &.last-odds-cell{
                    padding:8px 12px;
                }
            }
            .full-time-cell{
                width: 101px;
            }
            .matchNo-cell{
                width: 56px;
            }
            .date-cell{
                padding-left: 12px;
                width: 88px;
            }
            .had-name{
                padding: 12px 8px;
            }
            .matchNo-cell{
                width: 64px;
            }
            .flag-cell{
                width: 42px;
            }
            .half-time-cell {
                white-space: nowrap;
                width: 96px;
                padding: 12px 8px;
            }
            .full-time-cell{
                width: 96px;
                padding: 12px 8px;
            }
            .void-half-time span{
                width: 192px;
            }
            .last-odds{
                width: 58px;
            }
            .detail-cell{
                width: 58px;
            }
        }
        .last-odds-cell {
            width: 58px;
        }
    }

    .fbOddsTable section.HFT .match-row .odds{
        gap: 16px 4px;
    }



    .fbOddsTable section > .header, .fbOddsTable section .match-row{
        grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [odds] 25% [h2h] 48px [end];
    }

    .fbOddsTable section.CRS>.header,
    .fbOddsTable section.CRS .match-row,
    .fbOddsTable section.FCS>.header,
    .fbOddsTable section.FCS .match-row {
        grid-template-columns: [first] 90px [frontEndId] 62px [tourn] 40px 1fr [clock] 70px [odds] 180px [h2h] 48px [end];
    }

    .fbOddsTable section.HFT > .header, .fbOddsTable section.HFT .match-row{
        grid-template-columns: [first] 90px [frontEndId] 62px [tourn] 40px 1fr minmax(230px, 40%) [h2h] 48px [end];
    }

    .fbOddsTable section.HIL>.match-row-container>.match-row, 
    .fbOddsTable section.FHL>.match-row-container>.match-row, 
    .fbOddsTable section.FCH>.match-row-container>.match-row, 
    .fbOddsTable section.CHL>.match-row-container>.match-row {
        grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [odds] 206px [h2h] 48px [end];
    }

    .fbOddsTable section.HIL>.header,
    .fbOddsTable section.FHL>.header,
    .fbOddsTable section.FCH>.header,
    .fbOddsTable section.CHL>.header {
        grid-template-columns: [first] 94px [frontEndId] 64px [tourn] 40px 1fr [clock] 70px [line] 62px [odds] 144px [h2h]  48px [end];
    }
    .fbOddsTable {
        section{
            &.HIL, &.FHL, &.CHL, &.FCH {
                .oddsLine {

                grid-template-columns: 62px 1fr;
                }
            }
        }
    }
}