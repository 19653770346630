@media only screen and (max-width: 1279px) {
    #divBetSlipNormal{
       .en & .menu-txt{
            flex-direction: column;
            width: min-content;
        }
        .login .link-account{

            > div{
                // align-items: flex-start;
                text-align: left;
            }
            .en & >div.menu-txt{
                align-items: unset;
            }
        } 
        .login-account input{
            width: 134px;
        }
        .logonState {
            .login-account {
                padding-left: 12px !important;
                padding-bottom: 8px;
            }
            .betslip-pcard{
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
            .signIn{
                min-width: 70px;
            }
            .link-account {
                margin-left: 0px !important;
            }
        }
         .panel {
            // padding: 0 8px;
            .bet-line, .login-answer-container{
                margin: 12px 8px 0;
            }
        }
        .confirm{
            width: 148px;
        }
        .loginTandC .loginTandCHeader{
            padding-left: 8px;
        }
        .loginTandC .loginTandCText{
            padding: 10px 8px 0 ;
        }
        .login-answer-content > .ekbaBtnArea {

            .leftArea{
                /* height: 71px; */
                width: 89px;
                justify-content: space-between;
                align-items: baseline;
            }
        }
        .preview-bot-area{
            padding: 20px 8px 0;
        }
    }
}

@media only screen and (max-width: 1345px) {
    #divBetSlipNormal{
        .login-account input{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}