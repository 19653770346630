.fbOddsTable section.FGS {
    /* background-color: #fff;
    border-radius: 4px; */
}

.fbOddsTable .match-row-container.FGS .match-row-m>.odds {
    grid-template-columns: none;
    justify-items: center;
    justify-content: center;
    padding: 6px !important;
    row-gap: 2px;
}

.fbOddsTable .match-row-containerr.FGS .match-row-m>.odds>div {
    display: flex;
}

.fbOddsTable .match-row-container.FGS .match-row-m>.odds>div:nth-child(3) {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100px;
    justify-content: space-around;
}

.fbOddsTable section .match-row-container.FGS>.match-row-m:nth-child(odd) {
    background-color: #F4F4F4;
}

.fbOddsTable section .match-row-container.FGS>.match-row-m:nth-child(even) {
    background-color: #fff;
}

.fbOddsTable .match-row-container.FGS .match-row-m {
    grid-template-columns: 50% 50%;
}

.fbOddsTable .match-row-container.FGS .match-row.zero,
.fbOddsTable .match-row-container.FGS .match-row-m.zero {
    grid-template-columns: 100%;
    justify-items: center;
}

.fbOddsTable .match-row-container.FGS .match-row.zero>div {
    display: inline-grid;
    grid-template-columns: 200px 1px 80px;
    justify-content: center;
    width: 280px;
    justify-items: center;
    align-items: center;
}

.fbOddsTable .match-row-container.FGS .match-row.zero>div>div {
    display: inline-flex;
    justify-content: space-between;
    min-width: 60px;
}

.fbOddsTable .match-row-container.FGS .match-row {
    grid-template-columns: 50% 50%;
}

.fbOddsTable .match-row-container.FGS .match-row .odds {
    grid-template-columns: 180px 1px 80px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
}

.fbOddsTable .match-row-container.FGS .match-row .odds>span {
    /* width: 1px;
    height: 20px;
    background-color: #979797; */
    width: 1px;
    /* height: 20px; */
    border-right: #979797 1px solid;
    margin: 2px 0;
}

.fbOddsTable section .match-row-container.FGS .match-row:nth-child(even) {
    background-color: #f4f4f4;
}

.fbOddsTable section.ALLODDS .team.FGS {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    background-color: #fff;
    height: auto;
    min-height: 30px;
    background-color: #f4f4f4;
}

.fbOddsTable section.FGS .coupon {
    border-radius: 4px 4px 0px 0px;
}

.fbOddsTable section.FGS .odds>div:first-child {
    width: 100%;
    text-align: center;
}

.fbOddsTable section.FGS .odds>div:last-child, .fbOddsTable section.ALLODDS .FGS .odds>div:last-child {
    justify-content: flex-start;
    text-align: center;
    padding-left: 20px;
}